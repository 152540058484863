import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";

// Register required Chart.js components
ChartJS.register(BarElement, LineElement, CategoryScale, LinearScale, Tooltip, Legend);

const CombinedChartComponent = ({ apiResponse }) => {
  // Validate and extract labels from DailyDetails
  const labels =
    Array.isArray(apiResponse?.DailyDetails) && apiResponse.DailyDetails.length > 0
      ? apiResponse.DailyDetails.map((detail) => {
          if (detail.Label.includes("AM") || detail.Label.includes("PM")) {
            return detail.Label.split(" ")[0];
          }

          const match = detail.Label.match(/Year\s*=\s*(\d+),\s*Month\s*=\s*(\d+)/);
          return match ? `${match[1]}-${match[2].padStart(2, "0")}` : detail.Label;
        })
      : ["No Data"];

  // Define combined datasets
  const datasets = [
    {
      type: "bar",
      label: "Completed Quizzes",
      data: Array.isArray(apiResponse?.DailyDetails)
        ? apiResponse.DailyDetails.map((detail) => detail.CompletedQuizzes || 0)
        : [0],
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
    },
    {
      type: "bar",
      label: "Correct Answers",
      data: Array.isArray(apiResponse?.DailyDetails)
        ? apiResponse.DailyDetails.map((detail) => detail.TotalCorrectAnswers || 0)
        : [0],
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
    },
    {
      type: "line",
      label: "Wrong Answers",
      data: Array.isArray(apiResponse?.DailyDetails)
        ? apiResponse.DailyDetails.map((detail) => detail.TotalWrongAnswers || 0)
        : [0],
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 2,
      fill: false,
    },
    {
      type: "line",
      label: "InCompleted Quizzes",
      data: Array.isArray(apiResponse?.DailyDetails)
        ? apiResponse.DailyDetails.map((detail) => detail.InCompleteQuizzes || 0)
        : [0],
      borderColor: "rgba(255, 206, 86, 1)",
      borderWidth: 2,
      fill: false,
    },
  ];

  // Prepare chart data
  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  // Configure chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time Period",
        },
      },
      y: {
        title: {
          display: true,
          text: "Counts",
        },
      },
    },
  };

  return (
    <div>
      <h2>Combined Chart of User Activity</h2>
      <Chart type="bar" data={chartData} options={options} />
    </div>
  );
};

export default CombinedChartComponent;
