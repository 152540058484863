// AdminFeedback.jsx

import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usePreference } from "../context/UserContext";
import Swal from "sweetalert2"; // SweetAlert2 for notifications
import "./AdminFeedback.css"; // Import the custom CSS

function AdminFeedback() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState("");
  const [replyText, setReplyText] = useState(""); // To store the reply
  const [replyError, setReplyError] = useState(""); // To store error for empty reply
  const [showReplyModal, setShowReplyModal] = useState(false); // To show reply modal
  const [showcReplyModal, setShowcReplyModal] = useState(false); // To show reply modal

  const [selectedFeedback, setSelectedFeedback] = useState(null); // Track selected feedback
  const [sortOrder, setSortOrder] = useState("asc"); // State to track sorting order
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(
    "customerFeedback"
  ); // Track selected feedback type (customer or work)

  const username = localStorage.getItem("username");
  const { updateIsNavVisible } = usePreference();

  useEffect(() => {
    updateIsNavVisible(true);
    if (validateDates()) {
      fetchFeedbacks();
    }
  }, [startDate, endDate,selectedFeedbackType]);

  const validateDates = () => {
    if (startDate > endDate) {
      setError("Start date cannot be later than end date.");
      return false;
    }
    setError("");
    return true;
  };

  const fetchFeedbacks = () => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    let url;
    if (selectedFeedbackType == "customerFeedback") {
      url = `https://s3napi.s3nsoftware.com/api/UserMaster/GetAllCustomerFeedbacks?startDate=${formattedStartDate}&endDate=${formattedEndDate}&userName=${username}`;
    } else {
      url = `https://s3napi.s3nsoftware.com/api/CustomerFeedbacks/GetUserComment?startdate=${formattedStartDate}&enddate=${formattedEndDate}&username=${username}`;
    }

    let config = {
      method: "post",
      url: url,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setFeedbacks(response.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const handleReplyClick = (feedback) => {
    setSelectedFeedback(feedback);
    setShowReplyModal(true);
    setReplyError("");
    setReplyText("");
  };

  const handleCReplyClick = (feedback) => {
    setShowcReplyModal(true)
    setSelectedFeedback(feedback);
    //setShowReplyModal(true);
    setReplyError("");
    setReplyText("");
  };

  const handleSendReply = async () => {
    if (!replyText.trim()) {
      setReplyError("Reply cannot be empty.");
      return;
    }

    const replyData = {
      Feedback: replyText,
      Username: selectedFeedback.Username,
      Id: selectedFeedback.Id,
      Email: selectedFeedback.UserEmailID,
    };

    try {
      const response = await axios.post(
        "https://s3napi.s3nsoftware.com/api/UserMasters/ReplyFeedback",
        replyData
      );

      if (response.status === 200) {
        const updatedFeedbacks = feedbacks.map((feedback) => {
          if (feedback.Id === selectedFeedback.Id) {
            // Corrected to use 'Id'

            return { ...feedback, Answered: true,
              AnsweredOn: new Date().toISOString(),
              Reply: replyText,
              Answered: true, // Setting ReplyStatus to true
            };
          }
          return feedback;
        });

        setFeedbacks(updatedFeedbacks);
        setShowReplyModal(false);
        setReplyText("");
        Swal.fire("Success", "Reply sent successfully!", "success");
      }
    } catch (error) {
      //console.error("Error sending reply:", error);
      Swal.fire("Error", "There was an issue sending the reply.", "error");
    }
  };

  const handlecSendReply = async () => {
    if (!replyText.trim()) {
      setReplyError("Reply cannot be empty.");
      return;
    }

    const replyData = {
      Reply: replyText,
      Username: username,
      Id: selectedFeedback.Id,
      Email: selectedFeedback.UserEmailId,
    };

    try {
      const response = await axios.post(
        "https://s3napi.s3nsoftware.com/api/UserMasters/ReplyComment",
        replyData
      );

      if (response.status === 200) {
        const updatedFeedbacks = feedbacks.map((feedback) => {
          if (feedback.Id === selectedFeedback.Id) {
            // Corrected to use 'Id'
            return { ...feedback, 
              AnsweredOn: new Date().toISOString(),
              Reply: replyText,
              ReplyStatus: true, // Setting ReplyStatus to true
            };

          }
          return feedback;
        });

        setFeedbacks(updatedFeedbacks);
        setShowReplyModal(false);
        setShowcReplyModal(false);
        setReplyText("");
        Swal.fire("Success", "Reply sent successfully!", "success");
      }
    } catch (error) {
      //console.error("Error sending reply:", error);
      Swal.fire("Error", "There was an issue sending the reply.", "error");
    }
  };

  const sortByUsername = () => {
    const sortedFeedbacks = [...feedbacks].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.Username.toLowerCase() > b.Username.toLowerCase() ? 1 : -1;
      } else {
        return a.Username.toLowerCase() < b.Username.toLowerCase() ? 1 : -1;
      }
    });

    setFeedbacks(sortedFeedbacks);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleFeedbackTypeChange = (e) => {
    setSelectedFeedbackType(e.target.value);
    // Optionally, fetch feedbacks based on type here
  };

  const WorkFeedback = () => {
    return <div>Work Feedback will be displayed here.</div>;
  };

  // Helper function to format date to ddMMMyy
  const formatDateToDDMMMyy = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = String(date.getFullYear()).slice(-2);
    return `${day}${month}${year}`;
  };

  return (
    <div className="container mt-5 feedback-container">
      <h2 className="mb-4">Feedback Management</h2>

      {/* Radio buttons for selecting feedback type */}

      <div className="row mb-4">
        <div className="col-md-4 col-12">
          <label>Start Date:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="yyyy-MM-dd"
            className="form-control"
          />
        </div>
        <div className="col-md-4 col-12">
          <label>End Date:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="yyyy-MM-dd"
            className="form-control"
          />
        </div>
        <div className="col-md-4 col-12 d-flex align-items-center">
          <label className="mr-2">
            <input
              type="radio"
              value="customerFeedback"
              checked={selectedFeedbackType === "customerFeedback"}
              onChange={handleFeedbackTypeChange}
            />
            Customer Feedback
          </label>
          <label>
            <input
              type="radio"
              value="workFeedback"
              checked={selectedFeedbackType === "workFeedback"}
              onChange={handleFeedbackTypeChange}
            />
            Work Feedback
          </label>
        </div>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      {selectedFeedbackType === "customerFeedback" ? (
        <>
          <div className="table-container">
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Sno</th>
                    <th>Id</th>
                    <th style={{ cursor: "pointer" }} onClick={sortByUsername}>
                      Username
                      {sortOrder === "asc" ? (
                        <span
                          style={{
                            color: "blue",
                            marginLeft: "5px",
                            fontSize: "20px",
                          }}
                        >
                          &#8593;
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "green",
                            marginLeft: "5px",
                            fontSize: "20px",
                          }}
                        >
                          &#8595;
                        </span>
                      )}
                    </th>
                    <th>User Email ID</th>
                    <th>Comment</th>
                    <th>Subject</th>
                    <th>First Name</th>
                    <th>Mobile Number</th>
                    <th>Updated On</th>
                    <th>Answered On</th>
                    <th>Answered</th>
                    <th>Comment Status</th>
                    <th>Reply</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {feedbacks.map((feedback, index) => (
                    <tr key={feedback.Id}>
                      <td>{index + 1}</td>
                      <td>{feedback.Id}</td>
                      <td>{feedback.Username}</td>
                      <td>{feedback.UserEmailID}</td>
                      <td>{feedback.Comment}</td>
                      <td>{feedback.Subject}</td>
                      <td>{feedback.FirstName}</td>
                      <td>{feedback.MobileNumber}</td>
                      <td>{formatDateToDDMMMyy(feedback.UpdatedOn)}</td>
                      <td>
                        {feedback.AnsweredOn
                          ? formatDateToDDMMMyy(feedback.AnsweredOn)
                          : "N/A"}
                      </td>
                      <td>{feedback.Answered ? "Yes" : "No"}</td>
                      <td>{feedback.CommentStatus ? "Active" : "Inactive"}</td>
                      <td>{feedback.Reply}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleReplyClick(feedback)}
                        >
                          Reply
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        // <WorkFeedback />
        <>
          <div className="table-container">
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Sno</th>
                    <th>Id</th>
                    <th style={{ cursor: "pointer" }} onClick={sortByUsername}>
                      Username
                      {sortOrder === "asc" ? (
                        <span
                          style={{
                            color: "blue",
                            marginLeft: "5px",
                            fontSize: "20px",
                          }}
                        >
                          &#8593;
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "green",
                            marginLeft: "5px",
                            fontSize: "20px",
                          }}
                        >
                          &#8595;
                        </span>
                      )}
                    </th>
                    <th>User Email ID</th>
                    <th>Comment</th>
                    <th>Word</th>
                    <th>QuizName</th>
                    <th>SubCatName</th>
                    <th>CategoryName</th>
                    <th>RepliedBy</th>
                    <th>Comment Status</th>
                    <th>Reply</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {feedbacks.map((feedback, index) => (
                    <tr key={feedback.Id}>
                      <td>{index + 1}</td>
                      <td>{feedback.Id}</td>
                      <td>{feedback.Username}</td>
                      <td>{feedback.UserEmailId}</td>
                      <td>{feedback.Comment}</td>
                      <td>{feedback.Word}</td>
                      <td>{feedback.QuizName}</td>
                      <td>{feedback.SubCatName}</td>
                      <td>{feedback.CategoryName}</td>
                      <td>{feedback.RepliedBy}</td>
                      <td>{feedback.ReplyStatus && (<>Yes</>)}</td>
                      <td>{feedback.Reply}</td>
                      <td>
                        <button className="btn btn-primary btn-sm" onClick={()=>handleCReplyClick(feedback)}>
                          Reply
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      {showReplyModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{
            display: "block",
            backgroundColor: "rgba(0,0,0,0.5)", // Semi-transparent backdrop
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ backgroundColor: "#caf0f8", color: "#0077b6" }}
              >
                <h5 className="modal-title">Reply to Feedback</h5>
                <button
                  type="button"
                  className="close btn"
                  onClick={() => setShowReplyModal(false)}
                  style={{ position: "absolute", right: "15px", top: "15px" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <strong>Subject:</strong> {selectedFeedback?.Subject}
                </p>
                <p>
                  <strong>Comment:</strong> {selectedFeedback?.Comment}
                </p>
                <textarea
                  className="form-control"
                  placeholder="Type your reply here..."
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  rows="4"
                />
                {replyError && (
                  <div className="alert alert-danger mt-2">{replyError}</div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowReplyModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSendReply}
                >
                  Send Reply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


{showcReplyModal && (
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{
            display: "block",
            backgroundColor: "rgba(0,0,0,0.5)", // Semi-transparent backdrop
          }}
        >
          <div
            className="modal-dialog"
            role="document"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ backgroundColor: "#caf0f8", color: "#0077b6" }}
              >
                <h5 className="modal-title">Reply to Feedback</h5>
                <button
                  type="button"
                  className="close btn"
                  onClick={() => setShowcReplyModal(false)}
                  style={{ position: "absolute", right: "15px", top: "15px" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                
                <p>
                  <strong>Comment:</strong> {selectedFeedback?.Comment}
                </p>
                <textarea
                  className="form-control"
                  placeholder="Type your reply here..."
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  rows="4"
                />
                {replyError && (
                  <div className="alert alert-danger mt-2">{replyError}</div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowcReplyModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handlecSendReply}
                >
                  Send Reply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// Helper function to format date to yyyy-MM-dd
const formatDate = (date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};

// Helper function to format date to ddMMMyy (e.g., 16Oct24)
const formatDateToDDMMMyy = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = String(date.getFullYear()).slice(-2);
  return `${day}${month}${year}`;
};

export default AdminFeedback;
