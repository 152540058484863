import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Paper,
  FormControl,
  InputLabel,
  Autocomplete,
  Checkbox,
  Button,
  Box,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import TablePagination from "@mui/material/TablePagination";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import AudioPlayer from "../quiz/AudioPlayer";
import Recorder from "../../common/Recorder";
import CheckBox from "@mui/icons-material/CheckBox";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ImportExcel from "./ImportExcel";
import Swal from "sweetalert2";
import { Label } from "reactstrap";
import EditableTextField from "./EditableTextField";
import TableDataRow from "./TableDataRow";
import { ToastContainer } from "react-toastify";
import { usePreference } from "../../context/UserContext";
import { FaCheck, FaInfo } from "react-icons/fa";
import AutocompleteComponent from "./AutocompleteComponent";

const columnDefinitions = [
  { id: "checkbox", label: "◼", minWidth: 50, maxWidth: 50 },
  { id: "review1", label: "Rv1", minWidth: 30, maxWidth: 30 },
  { id: "id", label: "#", minWidth: 50, maxWidth: 50 },
  { id: "word", label: "Word", minWidth: 150, maxWidth: 200 },
  { id: "cat", label: "Cat", minWidth: 100, maxWidth: 150 },
  { id: "subCat", label: "SubCat", minWidth: 100, maxWidth: 150 },
  { id: "links", label: "Links", minWidth: 150, maxWidth: 200 },
  { id: "audios", label: "Audio's", minWidth: 100, maxWidth: 150 },
  { id: "edit", label: "Edit", minWidth: 50, maxWidth: 100 },
  { id: "pron", label: "Pron", minWidth: 100, maxWidth: 150 },
  { id: "pos", label: "POS", minWidth: 100, maxWidth: 150 },
  { id: "defnA", label: "Defn(A)", minWidth: 200, maxWidth: 300 },
  { id: "defnT", label: "Defn(T)", minWidth: 200, maxWidth: 300 },
  { id: "etymA", label: "Etym(A)", minWidth: 200, maxWidth: 300 },
  { id: "etymT", label: "Etym(T)", minWidth: 200, maxWidth: 300 },
  { id: "sentA", label: "Sent(A)", minWidth: 200, maxWidth: 300 },
  { id: "sentT", label: "Sent(T)", minWidth: 200, maxWidth: 300 },
  { id: "question", label: "Question", minWidth: 200, maxWidth: 250 },
  { id: "answer", label: "Answer", minWidth: 200, maxWidth: 250 },
  { id: "choiceA", label: "Choice-A", minWidth: 100, maxWidth: 150 },
  { id: "choiceB", label: "Choice-B", minWidth: 100, maxWidth: 150 },
  { id: "choiceC", label: "Choice-C", minWidth: 100, maxWidth: 150 },
  { id: "choiceD", label: "Choice-D", minWidth: 100, maxWidth: 150 },
  { id: "choiceE", label: "Choice-E", minWidth: 100, maxWidth: 150 },
];

function Admin() {
  const [data, setData] = useState([]);
  const [editCoords, setEditCoords] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [fSubCat, setFSubCat] = useState([]);
  const [defn, setDefn] = useState([]);
  const [sent, setSent] = useState([]);
  const [etym, setEtym] = useState([]);
  const [reload, setReload] = useState(0);
  const [selectedCat, SetSelectedCat] = useState(0);
  const [selectedSCat, SelectedSCat] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [filter, setFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentWord, setCurrentWord] = useState("");
  const [currentWordId, setCurrentWordId] = useState(0);
  const [tempSubCat, setTempSubCat] = useState([]);

  const [fetchedCountf, setFetchedCountf] = useState(0);
  const [fetchedCountt, setFetchedCountt] = useState(1000);

  const [inquizzes, setInQuizzes] = useState(false);

  const [columnVisibility, setColumnVisibility] = useState(
    columnDefinitions.reduce((acc, col) => {
      acc[col.id] = true;
      return acc;
    }, {})
  );

  const {
    setVisiblitily,
    setIsLoggedIn,
    updateIsLoginOpen,
    isSignUpOpen,
    updateIsSignUpOpen,
    isLoginOpen,
    updateIsNavVisible,
  } = usePreference();

  const appToken = localStorage.getItem("webtoken");
  const username = localStorage.getItem("username");
  const auth = "Bearer " + appToken;

  useEffect(() => {
    updateIsNavVisible(true);
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://s3napi.s3nsoftware.com/api/Categories",
      headers: {
        Authorization: auth.toString(),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCatData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        ////console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://s3napi.s3nsoftware.com/api/Categories/GetSubCategories",
      headers: {
        Authorization: auth.toString(),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setSubCatData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        ////console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://s3napi.s3nsoftware.com/api/QuizDatas/GetMasterData?from=${fetchedCountf}&records=${fetchedCountt}&catid=${selectedCat}&subcatid=${selectedSCat}&username=${username}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.toString(),
      },
    };

    axios
      .request(config)
      .then((response) => {
        ////console.log(config.url)
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        ////console.log(error);
        setLoading(false);
      });
  }, [selectedCat, selectedSCat, fetchedCountf, fetchedCountt]);

  let filterSubcategories = [];
  const handleCellClick = (rowId, fieldName, fieldValue) => {
    setEditCoords({ rowId, fieldName });
    setEditValue(fieldValue);

    if (fieldName === "SubCat") {
      let catId = filteredData.find((f) => f.Id == rowId).CatId;
      filterSubcategories = subCatData.filter((item) => item.CatId == catId);
      setFSubCat(filterSubcategories);
    } else {
      filterSubcategories = subCatData;
      setFSubCat(filterSubcategories);
    }
  };

  const [newPP, setNewPP] = useState(false);

  const updateNewPP = (value) => {
    setNewPP(value);
  };

  const handleTextChange = (event) => {
    const newValue = event?.target?.value || "";
    setEditValue(newValue);
  };

  const handleSave = (rowId, fieldName, newValue) => {
    const valueToSave = newValue || "";

    const replaceAllOccurrences = (text, word) => {
      const regex = new RegExp(`\\b${word}\\b`, "gi");
      return text.replace(regex, "____");
    };

    const newData = data.map((item) => {
      if (item.Id === rowId) {
        let updatedItem = { ...item, [fieldName]: valueToSave };

        // If the field being edited is `defn`, update `Quizdefn` as well
        if (fieldName === "defn") {
          updatedItem.Quizdefn = replaceAllOccurrences(
            valueToSave,
            updatedItem.Word
          );
        }

        if (fieldName === "etym") {
          updatedItem.Quizetym = replaceAllOccurrences(
            valueToSave,
            updatedItem.Word
          );
        }
        if (fieldName === "sent") {
          updatedItem.Quizsent = replaceAllOccurrences(
            valueToSave,
            updatedItem.Word
          );
        }

        return updatedItem;
      }
      return item;
    });

    setData(newData);
    setEditCoords(null);
    setEditValue("");

    let header = fieldName;

    switch (fieldName) {
      case "Word":
        header = "Word";
        break;
      case "Pron":
        header = "Pron";
        break;
      case "pos":
        header = "POS";
        break;
      case "defn":
        header = "Definition";
        break;
      case "Quizdefn":
        header = "Text Definition";
        break;
      case "etym":
        header = "Etymology";
        break;
      case "Quizetym":
        header = "Text Etymology";
        break;
      case "sent":
        header = "Sentence";
        break;
      case "Quizsent":
        header = "Text Sentence";
        break;
      default:
        header = fieldName;
        break;
    }

    sendSavingData(header, valueToSave, rowId, !inquizzes, username);
  };

  const [lastupdated, setLastupdated] = useState({});

  const sendSavingData = async (header, value, wordid, InQuizzes, Username) => {
    const dataa = {
      Header: header,
      Value: value,
      WordId: wordid,
      InQuizzes: InQuizzes,
      username: Username,
      NewPP: newPP,
    };

    try {
      const response = await axios.post(
        "https://s3napi.s3nsoftware.com/api/QuizDatas/AutoSaving",
        dataa
      );
      ////console.log("Server response:", response.data);

      if (response.data == "0" || response.data == "1") {
      } else {
        setLastupdated(response.data.wul);

        let newItem = response.data.qData; // The updated item

        const updatedData = data.map((item) => {
          // If the Id matches, replace the item with the new item
          if (item.Id === newItem.Id) {
            return { ...item, ...newItem }; // Merge item properties with newItem
          }
          return item; // Otherwise, return the original item
        });

        setData(updatedData); // Update the state with the new data array
      }
    } catch (error) {
      //console.error("Error sending data:", error);
    }
  };

  const handleExcellDownload = () => {
    const data = [
      [
        "Word",
        "Pronunciation",
        "Partofspeech",
        "Etymology",
        "Sentence",
        "Definition",
        "Question",
        "Answer",
        "ChoiceA",
        "ChoiceB",
        "ChoiceC",
        "ChoiceD",
        "ChoiceE",
        "QuizType",
        "Category",
        "Subcategory",
      ],
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);

    const quiz_types = [
      ["Quiz Type", "Quiz Type Id"],
      ["Spelling", "1"],
      ["Vocabulary", "3"],
    ];
    const ws_quiz_types = XLSX.utils.aoa_to_sheet(quiz_types);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Template");
    XLSX.utils.book_append_sheet(wb, ws_quiz_types, "Quiz Types");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "ImportTemplate.xlsx"
    );
  };

  const handleExcellDownload2 = () => {
    const data = [
      {
        Word: "",
        Pronunciation: "",
        Partofspeech: "",
        Etymology: "",
        Sentence: "",
        Definition: "",
        Question: "",
        Answer: "",
        ChoiceA: "",
        ChoiceB: "",
        ChoiceC: "",
        ChoiceD: "",
        ChoiceE: "",
        QuizType: "",
        Category: "",
        Subcategory: "",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Template.xlsx");
  };

  const handleRoleChange = (event, rowId, fieldName) => {
    const newData = data.map((item) => {
      if (item.Id === rowId) {
        return { ...item, [fieldName]: event.target.value };
      }
      return item;
    });
    setData(newData);
    setEditCoords(null);
    setEditValue("");

    if (fieldName === "CatId") {
      filterSubcategories = subCatData.filter(
        (item) => item.CatId == event.target.value
      );
      setFSubCat(filterSubcategories);
    } else {
      filterSubcategories = subCatData;
      setFSubCat(filterSubcategories);
    }
  };

  const handleColumnVisibilityChange = (columnId) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnId]: !prevVisibility[columnId],
    }));
  };

  const toggleColumnVisibility = (columnId) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnId]: !prevVisibility[columnId],
    }));
  };

  const renderCell = (row, fieldName) => {
    const isEditable =
      editCoords &&
      editCoords.rowId === row.Id &&
      editCoords.fieldName === fieldName;
    const fieldValue = row[fieldName];

    const handleSavee = (newValue) => {
      //alert(`Saving ${newValue} for ${fieldName}`);
      handleSave(row.Id, fieldName, newValue);
      // Implement saving logic here, such as updating the state or making an API call
    };

    const onClose = () => {
      setEditCoords(null);
      setEditValue("");
    };

    const editableCellStyle = isEditable
      ? { minWidth: "400px", maxWidth: "600px" }
      : { minWidth: "400px", maxWidth: "600px" };

      const editableCellStyle2 = isEditable
      ? { minWidth: "200px", maxWidth: "300px" }
      : { minWidth: "200px", maxWidth: "300px" };

    if (isEditable) {
      return (
        <TableCell style={
          ["Word","CatId","SubCat","etym","Review1"].includes(fieldName) ? editableCellStyle2 : editableCellStyle
        }>
          {fieldName === "CatId" ? (
            <FormControl fullWidth>
              <InputLabel id="select-label">Category</InputLabel>
              <Select
                labelId="select-label"
                value={editValue}
                onChange={(event) => handleRoleChange(event, row.Id, fieldName)}
                displayEmpty
                autoFocus
              >
                {catData.map((option, index) => (
                  <MenuItem key={index} value={option.Id}>
                    {option.CategoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : fieldName === "SubCat" ? (
            <FormControl fullWidth>
              <InputLabel id="select-label">SubCategory</InputLabel>
              <Select
                labelId="select-label"
                value={editValue}
                onChange={(event) => handleRoleChange(event, row.Id, fieldName)}
                displayEmpty
                autoFocus
              >
                {fSubCat.map((option, index) => (
                  <MenuItem key={index} value={option.Id}>
                    {option.SubCatName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : // ['defn'].includes(fieldName) ? (
          //   <AutocompleteComponent fieldName={fieldName} row={row} handleSave={handleSave}   defaultValue={fieldValue}  />
          // ) : ['sent'].includes(fieldName) ? (
          //   <AutocompleteComponent fieldName={fieldName} row={row} handleSave={handleSave}   defaultValue={fieldValue}  />
          // ) :
          ["etym", "defn", "sent", "pos", "Pron"].includes(fieldName) ? (
            <AutocompleteComponent
              fieldName={fieldName}
              row={row}
              handleSave={handleSave}
              defaultValue={fieldValue}
              onClose={onClose}
              updateNewPP={updateNewPP}
            />
          ) : (
            <EditableTextField
              value={fieldValue}
              onSave={handleSavee}
              label={fieldName}
              onClose={onClose}
            />
          )}
        </TableCell>
      );
    } else {
      return (
        <TableCell
        style={
          ["pos", "Pron","Word","CatId","SubCat","etym","Review1Text"].includes(fieldName) ? editableCellStyle2 : editableCellStyle
        }
          onClick={() => handleCellClick(row.Id, fieldName, fieldValue)}
          //onMouseEnter={() => handleCellClick(row.Id, fieldName, fieldValue)}
          //onMouseLeave={() => onClose && onClose()}
        >
          {fieldName === "CatId" ? (
            getCategoryNameById(fieldValue)
          ) : fieldName === "SubCat" ? (
            getSubCategoryNameById(fieldValue)
          ) : fieldName === "Review1" ? (
            fieldValue ? (
              <FaCheck />
            ) : (
              <FaInfo />
            )
          ) : (
            fieldValue
          )}
        </TableCell>
      );
    }
  };

  const getCategoryNameById = (id) => {
    const category = catData.find((category) => category.Id === id);
    return category ? category.CategoryName : null;
  };

  const getSubCategoryNameById = (id) => {
    const subcategory = subCatData.find((subcat) => subcat.Id === id);
    return subcategory ? subcategory.SubCatName : null;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 0));
    setPage(0);
  };

  const handleRecordCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedRecords((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedRecords((prevSelected) =>
        prevSelected.filter((recordId) => recordId !== id)
      );
    }
  };

  const handleSelectAllCheckboxChange = (event) => {
    const allRecordsHaveReview1 = data.every((record) => record.Review1);

    if (allRecordsHaveReview1) {
      setSelectAll(event.target.checked);
      if (event.target.checked) {
        setSelectedRecords(data.map((record) => record.Id));
      } else {
        setSelectedRecords([]);
      }
    } else {
      Swal.fire({
        title: "Review Not Completed",
        text:
          "Some reviews are not complete. Please complete all reviews before selecting.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleSelectChange = (event, id) => {
    const selectedValue = event.target.value;
    setSelectedRecords((prevSelected) => {
      if (selectedValue === "selected") {
        return [...prevSelected, id];
      } else {
        return prevSelected.filter((recordId) => recordId !== id);
      }
    });
  };

  const handleOutsideClick = async () => {
    ////console.log("Selected Records:", selectedRecords);

    const { value: userInput } = await Swal.fire({
      title: "Number of questions each quiz",
      input: "number",
      inputLabel: "Your input",
      inputPlaceholder: "Enter a positive integer only",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      inputAttributes: {
        step: 1,
        min: 1,
      },
      inputValidator: (value) => {
        if (!value) {
          return "Input is required!";
        }
        const parsedValue = parseInt(value, 10);
        if (
          isNaN(parsedValue) ||
          !Number.isInteger(parsedValue) ||
          parsedValue <= 0
        ) {
          return "You need to enter a valid positive integer!";
        }
        return null;
      },
    });

    if (userInput == undefined) {
      return;
    }

    const integerValue = parseInt(userInput, 10);
    if (selectedSCat == 0) {
      alert("Please select subcategory also");
      return;
    }

    const updatedData = {
      Username: username,
      NumberOfQuestion: integerValue,
      ListData: selectedRecords,
      CatId: selectedCat,
      Subcat: selectedSCat,
    };
    let dataa = JSON.stringify(updatedData);

    sendDataToServer(dataa);
  };

  const sendDataToServer = async (data) => {
    setLoading(true);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://s3napi.s3nsoftware.com/api/QuizDatas/CreateQuizzes",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        ////console.log(JSON.stringify(response.data));

        Swal.fire({
          title: "Quizzes Created!",
          icon: "success",
          confirmButtonText: "Ok",
        });

        setLoading(false);
      })
      .catch((error) => {
        ////console.log(error);
        setLoading(false);
      });
  };

  const handleClickEditAudio = (status, Wid, Word) => {
    setIsOpen(status);
    setCurrentWord(Word);
    setCurrentWordId(Wid);
  };

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filteredData = data.filter((item) => {
    const matchesCategory = selectedCat === 0 || item.CatId === selectedCat;
    const matchesSubcategory =
      selectedSCat === 0 || item.SubCat === selectedSCat;
    const normalizedWord = removeAccents(item.Word.toLowerCase());
    const normalizedFilter = removeAccents(filter.toLowerCase());

    return (
      matchesCategory &&
      matchesSubcategory &&
      normalizedWord.includes(normalizedFilter)
    );
  });

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table_data.xlsx");
  };

  const handleChangeCtFilter = (event) => {
    SetSelectedCat(event.target.value);

    const filteredSubcategories = subCatData.filter(
      (subcategory) => subcategory.CatId === event.target.value
    );
    setTempSubCat(filteredSubcategories);
  };

  const handleChangeSCtFilter = (event) => {
    SelectedSCat(event.target.value);
    setReload(reload + 1);
  };

  const hasData =
    lastupdated &&
    lastupdated.Word &&
    lastupdated.UpdateType &&
    lastupdated.Cat &&
    lastupdated.Subcat &&
    lastupdated.Username &&
    lastupdated.UpdateData;

  const headerStyles =
    isOpen ?? isSignUpOpen ?? isLoginOpen
      ? {
          position: "relative",
          zIndex: 0, // Higher than the modal
          backgroundColor: "yellow", // Example: highlight with yellow
          color: "red", // Example: change text color to red
        }
      : {};

  const handleCheckboxChange = (event) => {
    setInQuizzes(event.target.checked);
  };

  const [sortColumn, setSortColumn] = useState(null); // Column to sort
  const [sortDirection, setSortDirection] = useState("asc"); // asc or desc

  const handleSort = (columnId) => {
    

    const isAsc = sortColumn === columnId && sortDirection === "asc";
    setSortColumn(columnId);

    if(columnId==='pron'){
       columnId='Pron'; 
    }else if(columnId==='word'){
        columnId='Word';
      }else if(columnId==='id'){
        columnId='Id';
      } else if(columnId==='cat'){
        columnId='CatId'
      } else if(columnId==='subCat'){
        columnId='SubCat'
      }else if(columnId==='defnA'){
        columnId='defn'
      }else if(columnId==='defnT'){
        columnId='Quizdefn'
      }else if(columnId==='etymA'){
        columnId='etym';
      }else if(columnId==='etymT'){
        columnId='Quizetym'
      }else if(columnId==='sentA'){
        columnId='sent'
      }else if(columnId==='sentT'){
        columnId='Quizsent';
      }else if(columnId==='question'){
        columnId='Question'
      }else if(columnId==='answer'){
        columnId='Answer'
      }else if(columnId==='choiceA'){
        columnId='ChoiceA'
      }else if(columnId==='choiceB'){
        columnId='ChoiceB'
      }else if(columnId==='choiceC'){
        columnId='ChoiceC'
      }else if(columnId==='choiceD'){
        columnId='ChoiceD'
      }else if(columnId==='choiceE'){
        columnId='ChoiceE'
      }else if(columnId==='audios'){
        columnId='audio'
      }else if(columnId==='review1'){
        columnId='Review1Text'
      }


    setSortDirection(isAsc ? "desc" : "asc");

    const sortedData = [...data].sort((a, b) => {
      const aValue = a[columnId] || ""; // Handle undefined/null as empty string
      const bValue = b[columnId] || "";

      if (typeof aValue === "string" && typeof bValue === "string") {
        // Compare strings case-insensitively
        return isAsc
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        // Compare numbers
        return isAsc ? aValue - bValue : bValue - aValue;
      }

      return 0; // Fallback for unsupported types
    });

    setData(sortedData);
  };

  return (
    <>
      <>
        <ToastContainer />
      </>

      <Recorder
        open={isOpen}
        updateIsOpen={setIsOpen}
        word={currentWord}
        wid={currentWordId}
      />

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          marginBottom: 2,
          alignItems: "center",
        }}
      >
        <Button variant="contained" color="primary" onClick={handleExport}>
          Export Table
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleExcellDownload}
        >
          Template
        </Button>
        <ImportExcel />
        <Box sx={{ display: "flex", gap: 2, flexGrow: 1 }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              value={selectedCat}
              onChange={handleChangeCtFilter}
              displayEmpty
            >
              {catData.map((option, index) => (
                <MenuItem key={index} value={option.Id}>
                  {option.CategoryName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedCat && (
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="subcategory-label">SubCategory</InputLabel>
              <Select
                labelId="subcategory-label"
                value={selectedSCat}
                onChange={handleChangeSCtFilter}
                displayEmpty
              >
                {tempSubCat.map((option, index) => (
                  <MenuItem key={index} value={option.Id}>
                    {option.SubCatName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <TextField
          value={fetchedCountf}
          onChange={(e) => setFetchedCountf(e.target.value)}
          // onBlur={() => handleSave(row.Id, fieldName)}
          style={{ width: 80 }}
          autoFocus
          fullWidth
        />
        -
        <TextField
          value={fetchedCountt}
          onChange={(e) => setFetchedCountt(e.target.value)}
          // onBlur={() => handleSave(row.Id, fieldName)}
          style={{ width: 80 }}
          autoFocus
          fullWidth
        />
        {/* <TextField
          value={editValue}
          placeholder="Editing value..."
          margin="normal"
          sx={{ flexGrow: 1, minWidth: 200 }}
        /> */}
        <TextField
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Filter by word"
          margin="normal"
          sx={{ flexGrow: 1, minWidth: 200 }}
        />
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "lightgray",
            borderRadius: "5px",
            padding: "2px",
          }}
        >
          <label style={{ cursor: "pointer", color: "black" }}>
            {inquizzes ? "Not in quizzes" : "In quizzes also"}
            <input
              type="checkbox"
              checked={inquizzes}
              onChange={handleCheckboxChange}
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                color: "green",
                backgroundColor: "gray",
              }}
            />
          </label>
        </div>

        {hasData ? (
          <div>
            {`Word: ${lastupdated.Word}, Type: ${lastupdated.UpdateType}, Cat: ${lastupdated.Cat}, Subcat: ${lastupdated.Subcat}, Username: ${lastupdated.Username}, UpdatedValue: ${lastupdated.UpdateData}`}
          </div>
        ) : (
          <div>No data available</div>
        )}

        {/* {`Word: ${lastupdated.Word}, Type: ${lastupdated.UpdateType}, Cat: ${lastupdated.Cat}, Subcat:${lastupdated.Subcat}, Username:${lastupdated.Username}, UpdatedValue:${lastupdated.UpdateData}`} */}
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          marginBottom: 2,
          alignItems: "center",
        }}
      >
        {columnDefinitions.map((column) => (
          <Label
            key={column.id}
            onClick={() => handleColumnVisibilityChange(column.id)}
            style={{
              cursor: "pointer",
              padding: "1px 1px",
              borderRadius: "4px",
              margin: "4px",
              fontSize: "12px",
              fontFamily: "monospace",
              fontWeight: columnVisibility[column.id] ? "bold" : "normal",
              color: columnVisibility[column.id] ? "green" : "orange",
              // backgroundColor: columnVisibility[column.id] ? "lightgreen" : "lightcoral",
            }}
          >
            {column.label}
          </Label>
        ))}
      </Box>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
          <Label>Loading...</Label>
        </Box>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ minWidth: 650 }}
            >
              <TableHead style={headerStyles}>
                <TableRow sx={{ height: 50 }}>
                  {columnDefinitions.map((column) =>
                    columnVisibility[column.id] ? (
                      column.id === "checkbox" ? (
                        <TableCell key={column.id}>
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllCheckboxChange}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          onClick={() => handleSort(column.id)} // Enable sorting on click
                          style={{ cursor: "pointer", userSelect: "none" }}
                        >
                          {column.label}
                          {sortColumn === column.id && (
                            <span>
                              {sortDirection === "asc" ? " 🔼" : " 🔽"}
                            </span>
                          )}
                        </TableCell>
                      )
                    ) : null
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    // <TableRow key={row.Id} sx={{ height: 50 }}>
                    //   <TableCell>
                    //     <Checkbox
                    //       checked={selectedRecords.includes(row.Id)}
                    //       onChange={(event) => handleRecordCheckboxChange(event, row.Id)}
                    //     />
                    //   </TableCell>

                    //   <TableCell>{row.Id}</TableCell>
                    //   {['Word'].map(field => renderCell(row, field))}
                    //   <TableCell>{['CatId'].map(field => renderCell(row, field))}</TableCell>
                    //   <TableCell>{['SubCat'].map(field => renderCell(row, field))}</TableCell>
                    //   <TableCell>
                    //     {
                    //       row.audio ? (
                    //         <AudioPlayer
                    //           audioUrl={`https://s3nsoftware.com/${row.audio.replace(/sound:\/\//g, "")}`}
                    //           currentIndex={1}
                    //           size={30}
                    //           color={'#000'}
                    //           init={false}
                    //           quiz={false}
                    //         />
                    //       ) : (
                    //         null
                    //       )
                    //     }
                    //   </TableCell>
                    //   <TableCell onClick={() => handleClickEditAudio(true, row.Id, row.Word)}>{<EditIcon />}</TableCell>
                    //   {['Pron', 'pos', 'defn', 'Quizdefn', 'etym', 'Quizetym', 'sent', 'Quizsent'].map(field => renderCell(row, field))}
                    //   {['Question', 'Answer', 'ChoiceA', 'ChoiceB', 'ChoiceC', 'ChoiceD', 'ChoiceE'].map(field => renderCell(row, field))}
                    // </TableRow>
                    <TableDataRow
                      key={row.Id}
                      row={row}
                      isSelected={selectedRecords.includes(row.Id)}
                      onRecordCheckboxChange={handleRecordCheckboxChange}
                      handleClickEditAudio={handleClickEditAudio}
                      renderCell={renderCell}
                      columnVisibility={columnVisibility} // Pass column visibility state
                      inquizzes={inquizzes}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOutsideClick}
          >
            Make Quizzes
          </Button>
          <TablePagination
            rowsPerPageOptions={[
              30,
              60,
              90,
              { value: filteredData.length, label: "All" },
            ]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}

export default Admin;
