import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode, getCountries, parsePhoneNumberFromString } from 'libphonenumber-js';
import countryData from 'country-data';
import "./userProfile.css";
//import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Swal from "sweetalert2";
import { MdOutlineFileUpload } from "react-icons/md";
import { usePreference } from "../../context/UserContext";

const UserProfile = () => {
  const [modalShow, setModalShow] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userDP, setUserDP] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [formChanged, setFormChanged] = useState(false);

  const inputRef = useRef(null);

  const username = localStorage.getItem("username");
  const webtoken = localStorage.getItem("webtoken");

  const { updateIsNavVisible,          profilePhoto, updateProfilePhoto
  } = usePreference();

  const [userForm, setUserform] = useState({
    Id: 0,
    FirstName: "",
    LastName: "",
    City: "",
    State: "",
    Country: "",
    FirstInserted: "",
    LastUpated: new Date().toISOString(),
    Studying: "",
    MobileNumber: "",
  });

  useEffect(() => {
    updateIsNavVisible(true);

    if (username) {
      const fetchUserInfo = async () => {
        try {
          const response = await axios.get(
            `https://s3napi.s3nsoftware.com/api/UserProfiles/UserProfile?Username=${username}`,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${webtoken}`,
              },
            }
          );
          if (response.status === 200) {
            setUserInfo(response.data);
            setUserform({
              ...response.data,
              LastUpated: new Date().toISOString(), // Ensuring the last updated field is set
            });
          } else {
            //console.error("Failed to fetch data");
          }
        } catch (error) {
          //console.error("Error getting user info", error);
        }
      };
      fetchUserInfo();
    }
  }, [username]);

  const countries = getCountries().map((countryCode) => ({
    code: countryCode,
    name: new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode),
    callingCode: `+${getCountryCallingCode(countryCode)}`,
  }));

  const handleChangeMnumber = (phoneNumber) => {
    if (phoneNumber) {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber);
      if (parsedNumber) {
        const countryCode = parsedNumber.country;
        const selectedCountry = countries.find(country => country.code === countryCode);

        setUserform({
          ...userForm,
          MobileNumber: phoneNumber,
          Country: selectedCountry ? selectedCountry.name : "", // Set the corresponding country
        });
        setFormChanged(true);
      }
    } else {
      setUserform({
        ...userForm,
        MobileNumber: "",
        Country: "",
      });
      setFormChanged(true);
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountryName = e.target.value;
    const selectedCountry = countries.find(country => country.name === selectedCountryName);

    if (selectedCountry) {
      setUserform({
        ...userForm,
        Country: selectedCountryName,
        MobileNumber: `${selectedCountry.callingCode}${userForm.MobileNumber.replace(/^\+?[0-9]*/, '')}`,
      });
      setFormChanged(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserform({
      ...userForm,
      [name]: value,
    });
    setFormChanged(true);
  };

  const handleImgChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPhotoURL(URL.createObjectURL(file));
    }
  };

  const uploadCroppedImage = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append('image', selectedFile);
      formData.append("username", username);

      try {
        const response = await axios.post('https://s3napi.s3nsoftware.com/api/UserProfiles/UploadImageFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          //console.log('Image uploaded successfully:', response.data);
          setUserDP(response.data);
          localStorage.setItem('userphoto',response.data)
          updateProfilePhoto(response.data)
          setSelectedFile(null);
          Swal.fire({
            title: "Image Updated",
            text: "User Image Uploaded successfully",
            icon: "success",
            timer: 1200,
          });
        } else {
          throw new Error('Upload failed');
        }
      } catch (error) {
        //console.error('Error uploading image:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = {
      ...userForm,
    };
    if (userInfo.Id > 0) {
      try {
        const response = await fetch(
          `https://s3napi.s3nsoftware.com/api/UserProfiles/SaveUserProfile`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(form),
          }
        );
        if (response.status === 200) {
          setUserInfo(form);
          Swal.fire({
            title: "Updated",
            text: "User Info Updated Successfully",
            icon: "success",
            timer: 1200,
          });
        }
      } catch (error) {
        console.error(
          "Error submitting form",
          error.response ? error.response.data : error.message
        );
      }
    }
  };

  useEffect(() => {
    if (username) {
      const fetchDP = async () => {
        try {
          const response = await axios.get(
            "https://s3napi.s3nsoftware.com/api/UserProfiles/UserProfileImage",
            {
              params: {
                Username: username,
              },
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${webtoken}`,
              },
            }
          );
          if (response.status === 200) {
            setUserDP(response.data);
          } else {
            //console.error("Failed to load image");
          }
        } catch (error) {
          //console.error("Error fetching display image", error);
        }
      };
      fetchDP();
    }
  }, [username, webtoken]);

  const ImgSelect = () => {
    if (inputRef.current) {
      inputRef.current.click();
    } else {
      //console.error("input Ref is undefined or null");
    }
  };

  const parentStyle = {
    position: "relative",
    height: "100vh",
    width: "100%",
    backgroundColor: "#d0d0d0"

  };

  const childStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    padding: "20px 40px",
    border: "2px solid #888",
    borderRadius: "10px",
    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)",
    textAlign: "center"
  };

  return (
    <>
      <div style={parentStyle}>
        <div style={childStyle}>
          <div className="board glow">
            <div className="" >
              <div className="avatar" onClick={ImgSelect}>
                <div className="shine"></div>
                <img
                  src={selectedFile ? `${photoURL}` : (userDP.length > 0 ? `https://s3nsoftware.com/profilepics/${userDP}.jpg` : 'https://i.pinimg.com/736x/cf/34/f0/cf34f0af5496bf6eadda07c8042b9279.jpg')}
                  alt="userpic"
                  className="profileimg "
                />
                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  onChange={handleImgChange}
                  style={{ display: "none", textDecoration: 'none' }}
                />
              </div>
              {selectedFile ?
                <div style={{ width: '100%', textAlign: 'center' }} >
                  <button className="imgbtn" onClick={uploadCroppedImage}>
                    <span>Save the Profile</span><MdOutlineFileUpload />
                  </button>
                </div>
                : null
              }
              <div className="userName">{userInfo.Username}</div>
            </div>
            <hr />
            <div className="details">
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col xs={4}>
                    <Form.Label className="label">First Name</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      name="FirstName"
                      value={userForm.FirstName || ""}
                      onChange={handleChange}
                      placeholder={userInfo.FirstName}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={4}>
                    <Form.Label className="label">Last Name</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      name="LastName"
                      value={userForm.LastName || ""}
                      onChange={handleChange}
                      placeholder={userInfo.LastName}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4}>
                    <Form.Label className="label">Mobile Number</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <div className="phone-input-wrapper">
                      <PhoneInput
                        className="phone-input-custom"
                        international
                        defaultCountry="US"
                        name="MobileNumber"
                        value={userForm.MobileNumber || ""}
                        onChange={handleChangeMnumber}
                        placeholder={userInfo.MobileNumber}
                      />
                    </div>
                  </Col>
                </Row>



                <Row className="mb-3">
                  <Col xs={4}>
                    <Form.Label className="label">City</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      name="City"
                      value={userForm.City || ""}
                      onChange={handleChange}
                      placeholder={userInfo.City}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={4}>
                    <Form.Label className="label">State</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      name="State"
                      value={userForm.State || ""}
                      onChange={handleChange}
                      placeholder={userInfo.State}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4}>
                    <Form.Label className="label">Country</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      as="select"
                      name="Country"
                      value={userForm.Country || ""}
                      onChange={handleCountryChange}
                      required
                    >
                      <option value="">Select Country</option>
                      {countries.map(country => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={4}>
                    <Form.Label className="label">Grade Level</Form.Label>
                  </Col>
                  <Col xs={8}>
                    <Form.Control
                      type="text"
                      name="Studying"
                      value={userForm.Studying || ""}
                      onChange={handleChange}
                      placeholder={userInfo.Studying}
                      required
                    />
                  </Col>
                </Row>
                {formChanged === true ? <Button type="submit">Update</Button> : null}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
