import React, { useEffect, useState } from "react";
import styles from "./WordModel.module.css";
import axios from "axios";
import AudioPlayer from "../components/quiz/AudioPlayer";

const FullWordModel = (props) => {
  const username = localStorage.getItem("username");
  const appToken = localStorage.getItem("webtoken");
  const auth = "Bearer " + appToken;
  const [audiolink, setAudioLink] = useState("");
  const [wik, setWik] = useState("");
  const [mwun, setMwun] = useState("");
  const [mwfree, setMwfree] = useState("");
  const [currentquestion, setCurrentQuestion] = useState({});

  useEffect(() => {
    if (props.selectedid === 0) return;
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://s3napi.s3nsoftware.com/api/QuizDatas/${props.selectedid}`,
      headers: {
        Authorization: auth.toString(),
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCurrentQuestion(response.data);
        let ad;

        if (response.data.audio == null) {
          const firstChar = response.data.Word.trim()
            ? response.data.Word.trim().charAt(0).toLowerCase()
            : "";
          ad =
            "sound://gtts_audio_files/" +
            firstChar +
            "/" +
            response.data.Word +
            ".mp3";
        } else {
          ad = response.data.audio;
        }

        const mp3 = ad.replace(/sound:\/\//g, "");
        const url = `https://s3nsoftware.com/${mp3}`;
        setAudioLink(url);

        setWik(`https://en.wiktionary.org/wiki/${response.data.Word}`);
        setMwun(
          `https://unabridged.merriam-webster.com/unabridged/${response.data.Word}`
        );
        setMwfree(
          `https://www.merriam-webster.com/dictionary/${response.data.Word}`
        );
      })
      .catch((error) => {
        //console.log(error);
      });
  }, [props.selectedid]);

  const closeModal = () => {
    props.updateIsOpen(false);
  };

  return (
    <div className={styles.modal_container}>
      {props.open && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <span className={styles.close} onClick={closeModal}>
              &times;
            </span>
            <div>
              <label
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontSize: 25,
                }}
              >
                {currentquestion.Word}
              </label>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: "30%",
                        fontWeight: "bold",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {"Part of speech: "}
                    </td>
                    <td
                      style={{
                        width: "70%",
                        textAlign: "start",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {currentquestion.pos}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "30%",
                        fontWeight: "bold",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {"Definition: "}
                    </td>
                    <td
                      style={{
                        width: "70%",
                        textAlign: "start",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {currentquestion.defn}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "30%",
                        fontWeight: "bold",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {"Etymology: "}
                    </td>
                    <td
                      style={{
                        width: "70%",
                        textAlign: "start",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {currentquestion.etym}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "30%",
                        fontWeight: "bold",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {"Sentence: "}
                    </td>
                    <td
                      style={{
                        width: "70%",
                        textAlign: "start",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {currentquestion.sent}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "30%",
                        fontWeight: "bold",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {"Pronunciation: "}
                    </td>
                    <td
                      style={{
                        width: "70%",
                        textAlign: "start",
                        border: "1px solid black",
                        padding: "8px",
                      }}
                    >
                      {currentquestion.Pron}{" "}
                      <AudioPlayer
                        audioUrl={audiolink}
                        currentIndex={1}
                        size={30}
                        color={"#000"}
                        init={false}
                        quiz={false}
                      />
                    </td>
                  </tr>
                  <tr style={{ marginTop: 25 }}>
                    <td
                      style={{
                        width: "50%",
                        fontWeight: "bold",
                        border: "1px solid black",
                        padding: "8px",
                        wordWrap: "break-word", // Ensure content wraps nicely in smaller screens
                      }}
                    >
                      {"References: "}
                    </td>
                    <td
                      style={{
                        width: "100%",
                        textAlign: "start",
                        border: "1px solid black",
                        padding: "8px",
                        display: "flex",
                        flexWrap: "wrap", // Wrap the content in smaller screens
                        justifyContent: "flex-start",
                      }}
                    >
                      <span
                        style={{
                          padding: 5,
                          backgroundColor: "#ff6a00",
                          borderRadius: 15,
                          marginRight: 5,
                          marginBottom: 5, // Ensure space between items when wrapping
                          display: "inline-block",
                        }}
                      >
                        <a href={wik} target="_blank" rel="noopener noreferrer">
                          Wiktionary
                        </a>
                      </span>
                      <span
                        style={{
                          padding: 5,
                          backgroundColor: "#ff0000",
                          borderRadius: 15,
                          marginRight: 5,
                          marginBottom: 5,
                          display: "inline-block",
                        }}
                      >
                        <a
                          href={mwun}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MW Unabridged
                        </a>
                      </span>
                      <span
                        style={{
                          padding: 5,
                          backgroundColor: "#008000",
                          borderRadius: 15,
                          marginRight: 5,
                          marginBottom: 5,
                          display: "inline-block",
                        }}
                      >
                        <a
                          href={mwfree}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MW Free
                        </a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FullWordModel;
