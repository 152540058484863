import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import Swal from 'sweetalert2';

const ImportExcel = () => {
  const [data, setData] = useState({
    Username: 'exampleUser',
    LstExcelData: [],
  });

  const handleFileUpload = (event) => {
    const username=localStorage.getItem('username');
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = 'Template'; // Specify the sheet name "Template"
      
      if (workbook.SheetNames.includes(sheetName)) {
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        
        // Map the sheet data to the required modal structure
        const lstExcelData = sheetData.slice(1).map((row) => ({
          Word: row[0] || '',
          Pronunciation: row[1] || '',
          PartOfSpeech: row[2] || '',
          Etymology: row[3] || '',
          Sentence: row[4] || '',
          Definition: row[5] || '',
          Question: row[6] || '',
          Answer: row[7] || '',
          ChoiceA: row[8] || '',
          ChoiceB: row[9] || '',
          ChoiceC: row[10] || '',
          ChoiceD: row[11] || '',
          ChoiceE: row[12] || '',
          QuizType: row[13] || '',
          Category: row[14] || '',
          Subcategory: row[15] || '',
        }));
        const username=localStorage.getItem('username');

        const updatedData = {
          Username: username, // This should be dynamic if needed
          LstExcelData: lstExcelData,
        };

        setData(updatedData);
        sendDataToServer(updatedData);
      } else {
        //console.error(`Sheet with name "${sheetName}" not found`);
      }
    };

    reader.readAsBinaryString(file);
  };

  const sendDataToServer = async (data) => {
    let dataa = JSON.stringify(data);
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://s3napi.s3nsoftware.com/api/QuizDatas/UploadExcel',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : dataa
    };
    
    axios.request(config)
    .then((response) => {
      ////console.log(JSON.stringify(response.data));
      Swal.fire({
        title: 'Uploaded!',
        icon: 'success',
        confirmButtonText: 'Ok'
      });

    })
    .catch((error) => {
      ////console.log(error);
    });
  };

  return (
    <div>
     
      <input type="file" onChange={handleFileUpload} accept=".xlsx, .xls" />
      {/* <div>
        <h2>Uploaded Data</h2>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div> */}
    </div>
  );
};

export default ImportExcel;
