import React, { useEffect, useState } from 'react';
import styles from './MyQuizzes.module.css'; // Import the CSS file

import axios from 'axios';
import { Link } from 'react-router-dom';
const MyQuizzes = () => {
    const [quizzes, setQuizzes] = useState([])
    const appToken = localStorage.getItem('webtoken')
    const username = localStorage.getItem('username')
    const auth = "Bearer " + appToken;

    useEffect(() => {
        let data = '';

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://s3napi.s3nsoftware.com/api/UserQuizs/GetQuizzes?Username=${username}`,
            headers: {
                'Authorization': auth.toString()
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                setQuizzes(response.data)
                ////console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                ////console.log(error);
            });

    }, [])

    return (
        <>{quizzes.map((item, index) => {
            return (
                <div className='container w-50'>
            <div key={index} className={styles.quiz_stats_container}>
                <div className={styles.progress_circle}>
                    <span>{item.Percentage}</span>
                </div>
                <div className={styles.quiz_details}>
                  <Link to={`/playquiz/${item.QuizId}`}><h3>{item.QuizNumber}</h3></Link>  
                    <div className={styles.stats}>
                        <span className={styles.correct}>{item.Description.split(',')[0]}</span>
                        <span className={styles.incorrect}>{item.Description.split(',')[1]}</span>
                        <span className={styles.unattempted}>{item.Description.split(',')[2]}</span>
                    </div>
                </div>
                <div className={styles.action_icons}>
                    <i className={styles.info_icon}>i</i>
                    <i className={styles.refresh_icon}>⟳</i>
                </div>
            </div>
            </div>)
        })}</>


    );
};

export default MyQuizzes;
