import React, { useEffect, useRef, useState } from 'react';
import { AiFillPlayCircle, AiFillPauseCircle } from 'react-icons/ai';
import styles from './AudioPlayer.module.css';
import { Icon } from '@mui/material';
import { Pause, PlayCircle } from '@mui/icons-material';

const AudioPlayer = (props) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    // const playAudio = () => {
    //     if (audioRef.current) {
    //         audioRef.current.play().catch(e => {
    //             //console.error("Error playing audio:", e);
    //             // Handle the error (e.g., display an error message)
    //         });
    //     }
    // };
    const playAudio = () => {
        if (audioRef.current) {
            // Ensure user interaction (play only after user clicks play)
            const playPromise = audioRef.current.play();
            
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        //console.log("Audio is playing");
                    })
                    .catch(e => {
                        alert("Audio playback failed. Please interact with the page first.");
                        //console.error("Error playing audio:", e);
                        // Display a friendly error message if needed
                        
                    });
            }
        }
    };
    

    const pauseAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
        }
    };

    const togglePlayPause = () => {
        if (isPlaying) {
            pauseAudio();
        } else {
            playAudio();
        }
    };

    useEffect(() => {
        if (props.init) {
            playAudio();
        }
    }, [props.audioUrl, props.currentIndex,props.replay]);

    useEffect(() => {
        const audioElement = audioRef.current;

        const handlePlay = () => {
            setIsPlaying(true);
        };

        const handlePause = () => {
            setIsPlaying(false);
        };

        const handleEnded = () => {
            setIsPlaying(false);
        };

        if (audioElement) {
            audioElement.addEventListener('play', handlePlay);
            audioElement.addEventListener('pause', handlePause);
            audioElement.addEventListener('ended', handleEnded);
        }

        return () => {
            if (audioElement) {
                audioElement.removeEventListener('play', handlePlay);
                audioElement.removeEventListener('pause', handlePause);
                audioElement.removeEventListener('ended', handleEnded);
            }
        };
    }, []);

    return (

        <div className={styles.playarea}>
        <div className={styles.audioPlayer}>
        <audio ref={audioRef} src={props.audioUrl} preload="auto" />
            
        {/* <button className="play-button" id="playPauseButton" onClick={togglePlayPause}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button> */}
       <div >

       {props.quiz ? (
                    // If props.quiz is true, use Font Awesome icons
                    <div>
                        {isPlaying ? (
                            <Icon 
                            size={props.size}
                                onClick={togglePlayPause}  className={styles.playButton}
                            />
                        ) : (
                            <Icon 
                            size={props.size}
                                 className={styles.playButton}
                                onClick={togglePlayPause}
                            />
                        )}
                    </div>
                ) : (
                    // If props.quiz is false, use React Icons
                    <div>
                        {isPlaying ? (
                            <Pause size={props.size}
                                onClick={togglePlayPause}  
                            />
                        ) : (
                            <PlayCircle size={props.size} 
                                onClick={togglePlayPause}
                            />
                        )}
                    </div>
                )}
            {/* {isPlaying ? (
               
                <FontAwesomeIcon size={props.size}
                icon={faPause}
                    onClick={togglePlayPause}  className={styles.playButton}
                />
            ) : (
                <FontAwesomeIcon size={props.size}
                icon={faPlay} className={styles.playButton}
                    onClick={togglePlayPause}
                />
                
            )} */}
            </div>
        </div>
        </div>
    );
};

export default AudioPlayer;
