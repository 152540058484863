import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  import { Line } from "react-chartjs-2";
import GraphComponent from "./GraphComponent";
  
  // Register components
  ChartJS.register(
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale, // This is for the x-axis scale
    Title,
    Tooltip,
    Legend
  );

  const graphOptions = {
    responsive: true,
    plugins: {
      legend: { display: true, position: "top" },
    },
    scales: {
      x: {
        type: "category", // Ensure "category" is registered here
        title: { display: true, text: "Date Range" },
      },
      y: {
        title: { display: true, text: "Activity Count" },
      },
    },
  };


const UserActivityTracker = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;

  const [selectedUser, setSelectedUser] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [timeFilter, setTimeFilter] = useState("days");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // Fetch users data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://s3napi.s3nsoftware.com/api/UserMaster/UsersRankings",
          {
            headers: { Accept: "application/json" },
          }
        );
        setUsers(response.data);
        setFilteredUsers(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  

  // Handle search/filter
  useEffect(() => {
    const results = users.filter((user) =>
      user.UserId.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(results);
    setCurrentPage(1); // Reset to first page when filtering
  }, [searchTerm, users]);

  // Handle sorting
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setFilteredUsers(sortedUsers);
  };

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Fetch and display graph data when a user is clicked
  const fetchGraphData = async (userId, filter, start, end) => {
    setSelectedUser(userId);
    try {
 // Construct the URL for debugging
 const url = `https://s3napi.s3nsoftware.com/api/UserMaster/UserGraph?username=${userId}&timeFilter=${filter}&startDate=${start.toISOString()}&endDate=${end.toISOString()}`;

      const response = await axios.get(
        url,
        {
          headers: { Accept: "application/json" },
        }
      );
      
      //console.log("Request URL:", response.config.url);


      // Adjust the response data mapping as per the API structure
      const data = {
        labels: response.data.labels || [], // Ensure labels exist
        datasets: [
          {
            label: "User Activity",
            data: response.data.values || [], // Ensure values exist
            borderColor: "rgba(75,192,192,1)",
            backgroundColor: "rgba(75,192,192,0.2)",
            fill: true,
          },
        ],
      };
  //console.log("Data:", data);


 // const validatedResponse = transformAndValidateLabels(response.data);

      setGraphData(response.data);
    } catch (err) {
      //console.error("Error fetching graph data", err);
      setError("Error fetching graph data");
    }
  };


  const handleUserClick = (userId) => {
    setTimeFilter("days");
    fetchGraphData(userId, "days", startDate, endDate);
  };

  const handleTimeFilterChange = (filter) => {
    setTimeFilter(filter);
    if (selectedUser) {
      fetchGraphData(selectedUser, filter, startDate, endDate);
    }
  };

  const handleDateChange = () => {
    if (selectedUser) {
      fetchGraphData(selectedUser, timeFilter, startDate, endDate);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Users Data</h2>

      {/* Search Filter */}
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by username"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Table */}
      <table className="table table-striped table-bordered">
  <thead className="thead-dark">
    <tr>
      <th style={{ width: "150px" }}>
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => handleSort("UserId")}
        >
          User ID
        </button>
      </th>
      <th style={{ width: "150px" }}>
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => handleSort("Rank")}
        >
          Rank
        </button>
      </th>
      <th style={{ width: "150px" }}>
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => handleSort("TotalQuizzes")}
        >
          Total Quizzes
        </button>
      </th>
      <th style={{ width: "150px" }}>
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => handleSort("CompletedQuizzes")}
        >
          Completed Quizzes
        </button>
      </th>
      <th style={{ width: "150px" }}>
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => handleSort("TotalCorrectAnswers")}
        >
          Total Correct Answers
        </button>
      </th>
      <th style={{ width: "150px" }}>
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => handleSort("TotalWrongAnswers")}
        >
          Total Wrong Answers
        </button>
      </th>
      <th style={{ width: "150px" }}>
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => handleSort("AvgCompletedPercentage")}
        >
          Avg Completed Percentage
        </button>
      </th>
      <th style={{ width: "150px" }}>
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => handleSort("AvgAttemptedPercentage")}
        >
          Avg Attempted Percentage
        </button>
      </th>
      <th style={{ width: "150px" }}>
        <button
          className="btn btn-link p-0 text-decoration-none"
          onClick={() => handleSort("AvgIncompletePercentage")}
        >
          Avg Incomplete Percentage
        </button>
      </th>
    </tr>
  </thead>
  <tbody>
    {currentUsers.map((user) => (
      <tr
        key={user.UserId}
        style={{ cursor: "pointer" }}
        onClick={() => handleUserClick(user.UserId)}
      >
        <td style={{ width: "150px" }}>{user.UserId}</td>
        <td style={{ width: "150px" }}>{user.Rank}</td>
        <td style={{ width: "150px" }}>{user.TotalQuizzes}</td>
        <td style={{ width: "150px" }}>{user.CompletedQuizzes}</td>
        <td style={{ width: "150px" }}>{user.TotalCorrectAnswers}</td>
        <td style={{ width: "150px" }}>{user.TotalWrongAnswers}</td>
        <td style={{ width: "150px" }}>
          {user.AvgCompletedPercentage.toFixed(2)}%
        </td>
        <td style={{ width: "150px" }}>
          {user.AvgAttemptedPercentage.toFixed(2)}%
        </td>
        <td style={{ width: "150px" }}>
          {user.AvgIncompletePercentage.toFixed(2)}%
        </td>
      </tr>
    ))}
  </tbody>
</table>


      {/* Pagination */}
      <nav>
        <ul className="pagination justify-content-center">
          {[...Array(totalPages).keys()].map((number) => (
            <li
              key={number + 1}
              className={`page-item ${
                currentPage === number + 1 ? "active" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(number + 1)}
              >
                {number + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {/* Graph */}
      {selectedUser && graphData && (
        <div className="mt-4">
          <h3>Activity for User: {selectedUser}</h3>
          <GraphComponent  apiResponse={graphData}  graphType="line" options={graphOptions} />
          <div className="d-flex justify-content-center mt-3">
            <button
              className={`btn btn-sm ${
                timeFilter === "days" ? "btn-primary" : "btn-secondary"
              } mx-1`}
              onClick={() => handleTimeFilterChange("days")}
            >
              Days
            </button>
            <button
              className={`btn btn-sm ${
                timeFilter === "weeks" ? "btn-primary" : "btn-secondary"
              } mx-1`}
              onClick={() => handleTimeFilterChange("weeks")}
            >
              Weeks
            </button>
            <button
              className={`btn btn-sm ${
                timeFilter === "months" ? "btn-primary" : "btn-secondary"
              } mx-1`}
              onClick={() => handleTimeFilterChange("months")}
            >
              Months
            </button>
            <button
              className={`btn btn-sm ${
                timeFilter === "years" ? "btn-primary" : "btn-secondary"
              } mx-1`}
              onClick={() => handleTimeFilterChange("years")}
            >
              Years
            </button>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-control mx-2"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              className="form-control mx-2"
            />
            <button
              className="btn btn-primary"
              onClick={handleDateChange}
            >
              Apply Date Range
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default UserActivityTracker;
