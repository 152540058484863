import React, { useEffect, useState } from "react";
import TextWithIcons from "../quiz/TextWithIcons";
import AudioPlayer from "./AudioPlayer";
import { useRef } from "react";
import { Style, SwipeLeftAlt } from "@mui/icons-material";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import AnimatedView from "./AnimatedView";
import ProgressBar from "./ProgressBar";

const QA = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const inputRef = useRef(null); // Create a ref for the input field

  const getSpecialCharacters = (inputString) => {
    // Use a regular expression to match special characters
    const specialCharacterRegex = /[^a-zA-Z0-9\s]/g;

    // Use the match() method to get an array of matched characters
    const specialCharactersArray = inputString.match(specialCharacterRegex);

    // Check if there are any special characters
    if (specialCharactersArray) {
      // Join the array into a string of special characters
      const specialCharactersString = specialCharactersArray.join("");
      return specialCharactersString;
    } else {
      return "";
    }
  };

  const checkInputValidations = (spelling) => {
    // Getting the actual word
    const actual_word = props.currentquestion.utfascii;
    // Getting only special characters in the original word
    const result = getSpecialCharacters(actual_word);

    // Process the last character typed
    const fValue = spelling.slice(-1);

    // If character not present in result
    if (!result.includes(fValue)) {
      // Concatenate only allowed characters
      return spelling.slice(0, -1) + fValue.replace(/[^A-Za-z]/g, "");
    }
    return spelling;
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    const validatedValue = checkInputValidations(newValue);
    setInputValue(validatedValue);
    // const filteredValue = newValue.replace(/[^a-zA-Z]/g, ''); // Remove non-alphabetic characters
    // setInputValue(filteredValue);
    // if (!event.target.value.match(/^[a-zA-Z]*$/)) {
    //   setError('Only letters are allowed');
    // } else {
    //   setError('');
    // }
  };

  // Function to set focus dynamically
  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on the input field
    }
  };

  const [isPosShow, setPosShow] = useState(true);
  const [isDefnShow, setDefnShow] = useState(true);
  const [isEtymShow, setEtymShow] = useState(true);
  const [isSentShow, setSentShow] = useState(true);

  const [isDisabled, setIsDisabled] = useState(false); // State to control enable/disable

  // Set focus automatically when the component mounts (optional)
  useEffect(() => {
    focusInput(); // Focus input when the component mounts
    setInputValue("");

    setPosShow(true);
    setEtymShow(true);
    setDefnShow(true);
    setSentShow(true);
    setIsDisabled(false);
    focusInput();
  }, [props.currentIndex]);

  useEffect(() => {
    setInputValue(props.currentText);
  }, [props.currentText]);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submit action

    if (!inputValue.trim()) {
      setError("Input cannot be empty");
      return;
    }
    ////console.log('Submitted value:', inputValue);
    // Add your submit logic here (e.g., sending data to a server).
    //suresh
    setIsDisabled(true); // Disable the input and button after submit
    props.handleGoClick(inputValue);
    setInputValue("");
  };

  const showall =
    "Part of speech: " +
    props.currentquestion.pos +
    "<br/>" +
    "Definition: " +
    props.currentquestion.Quizdefn +
    "<br/>" +
    "Etymology: " +
    props.currentquestion.Quizetym +
    "<br/>" +
    "Sentence: " +
    props.currentquestion.Quizsent;

  const showalla = [
    `Part of speech, ${props.currentquestion.pos}`,
    `, Definition, ${props.currentquestion.defn} `,
    `, Etymology, ${props.currentquestion.etym} `,
    `, Sentence, ${props.currentquestion.sent}`,
  ];

  const updateType = (value) => {
    if (value == "0") {
      setPosShow(!isPosShow);
      setEtymShow(!isEtymShow);
      setDefnShow(!isDefnShow);
      setSentShow(!isSentShow);
    } else if (value == "1") {
      setPosShow(!isPosShow);
    } else if (value == "2") {
      setDefnShow(!isDefnShow);
    } else if (value == "3") {
      setEtymShow(!isEtymShow);
    } else if (value == "4") {
      setSentShow(!isSentShow);
    }
  };

  const playerBgStyle = {
  background: `
    linear-gradient(145deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1)),
    linear-gradient(to bottom, #4a79c9 0%, #0b3476 100%)
  `,
  WebkitBackdropFilter: "blur(10px)", // Support for Webkit browsers
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  border: "1px solid rgba(255, 255, 255, 0.3)", // Subtle border for the glass effect
  borderRadius: "15px", // Rounded corners for better appearance
  padding: "30px",
  marginTop: "20px",
  marginBottom: "5px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Adds depth to the container

};


  const controlsStyle = {
    width: "80%",
    margin: "auto",
  };

  const answerInputsStyle = {
    justifyContent: "center",
    width: "100%",
  };

  const inputAnswerStyle = {
    borderRadius: "26px",

    color: "white", // Color of the input text
    backgroundColor: "transparent", // Example background color, adjust as needed
    border: "1px solid #ccc", // Example border, adjust as needed
    fontSize: "16px",
    padding: "8px",
    outline: "none",
    width: "100%",
    "::placeholder": {
      color: "white", // Placeholder color set to white
    },
  };

  return (
    <div>
      <div className="shadow" style={playerBgStyle}>
        <div className="row" style={controlsStyle}>
          <div className="col-sm-12 col-md-6 align-self-center">
            <div
              className="d-flex flex-column align-items-center align-content-center"
              id="player-controls"
            >
              <div className="d-flex justify-content-center align-items-center">
                <AnimatedView animationType="zoom">
                  <AiFillLeftCircle
                    className={`small-icon ${isDisabled ? "disabled" : ""}`} // Keep the same class names
                    onClick={!isDisabled ? props.handlePrevClick : null} // Disable click when isDisabled
                    style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                    data-bss-hover-animate="bounce" // Keep the hover animation data attribute
                  />
                </AnimatedView>

                {props.quiztype === 1 && (
                  <AudioPlayer
                    audioUrl={props.audiolink}
                    currentIndex={props.currentIndex}
                    color={"#fff"}
                    init={true}
                    quiz={true}
                    replay={props.replay}
                    isDisabled={isDisabled} // Pass disabled state to the AudioPlayer
                  />
                )}

                <AnimatedView animationType="zoom">
                  <AiFillRightCircle
                    className={`small-icon ${isDisabled ? "disabled" : ""}`} // Keep the same class names
                    onClick={!isDisabled ? props.handleNextClick : null} // Disable click when isDisabled
                    style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                    data-bss-hover-animate="bounce" // Keep the hover animation data attribute
                  />
                </AnimatedView>
              </div>

              <div className="row">
                <div className="col">
                  <p style={{ marginBottom: 0, marginTop: "15px" }}>
                    {props.currentIndex + 1}/{props.quiqsize}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 d-flex justify-content-center align-items-center">
            <form
              onSubmit={handleSubmit}
              className="d-flex"
              style={answerInputsStyle}
            >
              <div className="d-flex" style={answerInputsStyle}>
                <AnimatedView animationType="zoom">
                  <input
                  className="cust_input"
                    style={inputAnswerStyle}
                    type="text"
                    spellCheck="false"
                    onContextMenu={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                    onDrag={(e) => e.preventDefault()}
                    onDrop={(e) => e.preventDefault()}
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                    value={inputValue.toLocaleLowerCase()}
                    onChange={handleChange}
                    placeholder="Enter Spelling"
                    ref={inputRef} // Attach the ref to the input element
                    disabled={isDisabled} // Control disabled state
                    // inputMode="none"
                    // onSelect={(e) => e.preventDefault()}
                  />

                  <AiFillRightCircle
                    className={`submit-answer-icon ${
                      isDisabled ? "disabled" : ""
                    }`} // Keep the same class names
                    onClick={!isDisabled ? handleSubmit : null} // Disable click when isDisabled
                    style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                    data-bss-hover-animate="bounce" // Keep the hover animation data attribute
                  />
                </AnimatedView>

                
              </div>
            </form>
          </div>

          <style jsx>{`
            input::placeholder {
              color: white;
            }
          `}</style>
        </div>
        {/* <p>{props.answers}</p> */}
        {
          <ProgressBar
            tanswers={props.answers}
            currentIndex={props.currentIndex}
            quiqsize={new Array(props.quiqsize).fill(0)}
          />
        }

        <div className="hints-row">
          <div>
            <div className="row" style={{ marginBottom: "5px" }}>
              <div className="col-auto">
                <p data-bss-hover-animate="pulse" style={{ cursor: "pointer" }}>
                  <>
                    <TextWithIcons
                      text={"WORD INFORMATION"}
                      value={showall}
                      audio={showalla}
                      audioenable={true}
                      textenable={true}
                      textshow={true}
                      type={0}
                      updateType={updateType}
                      both={true}
                    />
                  </>
                </p>
              </div>
              {/* <div className="col-auto">
                <p data-bss-hover-animate="pulse" style={{ cursor: "pointer" }}>
                  <>
                    <TextWithIcons
                      text={""}
                      value={showall}
                      audio={showalla}
                      audioenable={true}
                      textenable={false}
                      textshow={true}
                      type={0}
                      updateType={updateType}
                      both={false}
                    />
                  </>
                </p>
              </div>

              <div className="col-auto">
                <p data-bss-hover-animate="pulse" style={{ cursor: "pointer" }}>
                  <>
                    <TextWithIcons
                      text={""}
                      value={showall}
                      audio={showalla}
                      audioenable={true}
                      textenable={true}
                      textshow={true}
                      type={0}
                      updateType={updateType}
                      both={true}

                    />
                  </>
                </p>
              </div> */}
            </div>

            <div className="row">
              <div
                className="col-sm-12 col-md-6"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <div className="hint-box-1">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between hint-content-top">
                        <div>
                          <p className="hint-type">Part of Speech</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center hint-content-top-icons">
                          {
                            <TextWithIcons
                              text={"Part of speech"}
                              value={props.currentquestion.pos}
                              audio={props.currentquestion.pos}
                              audioenable={true}
                              textenable={true}
                              textshow={false}
                              type={1}
                              updateType={updateType}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="hint-content-hint"
                        style={{
                          overflowY: "auto",
                          maxHeight: "60px",
                          textTransform: "none",
                        }}
                      >
                        {isPosShow ? props.currentquestion.pos : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-12 col-md-6"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <div className="hint-box-1">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between hint-content-top">
                        <div>
                          <p className="hint-type">Definition</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center hint-content-top-icons">
                          <div>
                            <TextWithIcons
                              text={"Definition"}
                              value={props.currentquestion.Quizdefn}
                              audio={props.currentquestion.defn}
                              audioenable={true}
                              textenable={true}
                              textshow={false}
                              type={2}
                              updateType={updateType}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="hint-content-hint"
                        style={{
                          overflowY: "auto",
                          maxHeight: "60px",
                          textTransform: "none",
                        }}
                      >
                        {isDefnShow ? props.currentquestion.Quizdefn : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "6px" }}>
              <div
                className="col-sm-12 col-md-6"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <div className="hint-box-1">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between hint-content-top">
                        <div>
                          <p className="hint-type">Etymology</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center hint-content-top-icons">
                          <div>
                            <TextWithIcons
                              text={"Etymology"}
                              value={props.currentquestion.Quizetym}
                              audio={props.currentquestion.etym}
                              audioenable={true}
                              textenable={true}
                              textshow={false}
                              type={3}
                              updateType={updateType}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="hint-content-hint"
                        style={{
                          overflowY: "auto",
                          maxHeight: "60px",
                          textTransform: "none",
                        }}
                      >
                        {isEtymShow ? props.currentquestion.Quizetym : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-6"
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <div className="hint-box-1">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex justify-content-between hint-content-top">
                        <div>
                          <p className="hint-type">Sentence</p>
                        </div>
                        <div className="d-flex justify-content-end align-items-center hint-content-top-icons">
                          <TextWithIcons
                            text={"Sentence"}
                            value={props.currentquestion.Quizsent}
                            audio={props.currentquestion.sent}
                            audioenable={true}
                            textenable={true}
                            textshow={false}
                            type={4}
                            updateType={updateType}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p
                        className="hint-content-hint"
                        style={{
                          overflowY: "auto",
                          maxHeight: "60px",
                          textTransform: "none",
                        }}
                      >
                        {isSentShow ? props.currentquestion.Quizsent : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default QA;
