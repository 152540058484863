
import React, { useRef, useState } from "react";
import Pause from "../../assets/img/pause.png";
import { FaVolumeUp,FaVolumeDown } from "react-icons/fa";

const TextToSpeech = ({ text }) => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const utteranceRef = useRef(null);
  
    const speakText = () => {
      if (!text) return;

      const _ttsPitch = localStorage.getItem("ttsPitch") || "1.0"; // Default TTS pitch
      const _ttsVolume = localStorage.getItem("ttsVolume") || "1.0"; // Default TTS volume
      const _ttsSpeed = localStorage.getItem("ttsSpeed") || "1.0"; // Default TTS speed
    

      utteranceRef.current = new SpeechSynthesisUtterance(text);

        utteranceRef.current.rate = _ttsSpeed;
        utteranceRef.current.pitch = _ttsPitch;
        utteranceRef.current.volume = _ttsVolume;


      utteranceRef.current.onend = () => setIsSpeaking(false);
      speechSynthesis.speak(utteranceRef.current);
      setIsSpeaking(true);
    };
  
    const stopSpeech = () => {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    };
  
    return (
      <span style={{ cursor: "pointer", marginLeft: "5px" }}>
        {isSpeaking ? (
          <FaVolumeDown onClick={stopSpeech} />
        ) : (
          <FaVolumeUp onClick={speakText} />
        )}
      </span>
    );
  };

  export default TextToSpeech;