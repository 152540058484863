import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import { usePreference } from "../../context/UserContext";

const PayPalModal = ({
  showPaypalModel,
  updateShowPaypalModel,
  amount,
  IsCollectionId,
  IsMonthly,
  SelectedId,
  Username,
  cartdata,
  filteredCart// cartdata is available globally in this component
}) => {
  const [paymentCompleted, setPaymentCompleted] = useState(false); // To show success message
  const paypalRef = useRef(); // To reference the PayPal button container

  const {
    updateCart,
  } = usePreference();

  

  // Load PayPal SDK dynamically
  const loadPayPalScript = (callback) => {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AbjTGxNRgw-BNbD2ILmTePbIWjsxHJs951dfZAOh73eJNK-BwkCpbNN_0AllDm7Fjo4GE_cqwjivh0st&intent=capture"; // Enable Venmo &enable-funding=venmo
    script.addEventListener("load", () => callback());
    document.body.appendChild(script);
  };

  // Close the modal and reset success message
  const handleClose = () => {
    updateShowPaypalModel(false);
    setPaymentCompleted(false); // Reset success message when modal closes
  };

  const generateInvoiceId = () => {
    const baseUuid = uuidv4();
    const extendedUuid = (baseUuid + baseUuid + baseUuid).slice(0, 99);
    return `INV-${extendedUuid.slice(0, 96)}`; // Adding "INV-" takes up 4 characters
  };

  // Truncate function to limit string length
  const truncateString = (str, maxLength) => {
    return str.length > maxLength ? str.slice(0, maxLength) : str;
  };

  // Initialize PayPal Buttons once script is loaded and modal is shown
  useEffect(() => {
    if (showPaypalModel) {
      if (!window.paypal) {
        loadPayPalScript(() => {
          initializePayPalButtons();
        });
      } else {
        initializePayPalButtons();
      }
    }
  }, [showPaypalModel]);

  // Format cartdata to match PayPal's expected item structure
  const formatCartItemsForPayPal = (cartdata) => {
    return cartdata.map((item) => ({
      name: truncateString(item.packname, 127),  // Adjust item name to meet PayPal's limit
      description: truncateString(item.packname || 'No description', 127),  // Adjust item description if available
      unit_amount: {
        currency_code: "USD",
        value: item.price,  // Ensure that the price is in USD
      },
      quantity: "1",//String(item.quantity || 1),  // PayPal expects quantity as a string
      category: "DIGITAL_GOODS"  // Change if it's a physical product
    }));
  };

  // Calculate total cart amount
  const calculateTotalAmount = (cartdata) => {
    return cartdata.reduce((total, item) => {
      return total + (item.price * 1);
    }, 0);
  };


  // Initialize the PayPal buttons
  const initializePayPalButtons = () => {
    const paypalItems = formatCartItemsForPayPal(cartdata);  // Format the cart items

    const totalAmount = calculateTotalAmount(cartdata);  // Calculate the total amount based on cartdata

    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          const invoiceId = generateInvoiceId(); // Generate unique invoice ID


          return actions.order.create({
            purchase_units: [
              {
                reference_id: "PUHF",
                description: "Purchase of digital goods",
                custom_id: JSON.stringify({
                  username: Username,
                  //IsCollectionId,
                  //IsMonthly,
                 // SelectedId
                }),
                invoice_id: invoiceId,
                soft_descriptor: "Digital Goods", // Shortened to meet 22 character limit
                amount: {
                  currency_code: "USD",
                  value: totalAmount.toFixed(2),
                  breakdown: {
                    item_total: {
                      currency_code: "USD",
                      value: totalAmount.toFixed(2),
                    },
                  },
                },
                items: paypalItems,  // Pass the formatted cart items to PayPal
              },
            ],
            // payment_source: {
            //   venmo: {
            //     experience_context: {
            //       shipping_preference: "NO_SHIPPING", // No shipping required for digital goods
            //       brand_name: "My Spelling!!!",
            //       locale: "en-US",
            //     },
            //   },
            // },
            //intent: "CAPTURE",  // Change to "AUTHORIZE" to authorize a payment only
            // application_context: {
            //   shipping_preference: "NO_SHIPPING", // No shipping required for digital goods
            //   brand_name: "My Spelling!!!",
            //   locale: "en-US",
            //   user_action: "PAY_NOW",
            // },
          });
        },
        onApprove: async (data, actions) => {
          try {
            const details = await actions.order
              .capture();
            //console.log("Payment captured:", details);
            setPaymentCompleted(true);
            savePayment(details, data); // Access cartdata here
          } catch (error) {
            //console.error("Error capturing payment:", error);
            savePaymentError(error, data); // Save the error data
            alert("An error occurred during the capture: " + error.message);
          }
        },
        onError: (err) => {
          //console.error("PayPal Checkout onError:", err);
          savePaymentError(err);  // Save the error data when checkout fails
          alert("An error occurred with the payment: " + err.message);
        },
      })
      .render(paypalRef.current); // Render the PayPal button
  };

  // Save payment details to the server, including cartdata
  const savePayment = async (details, data, status) => {
    const customData = JSON.parse(details.purchase_units[0].custom_id);

    const paymentInfo = {
      payerID: data.payerID,
      orderID: data.orderID,
      paymentID: details.id,
      paymentToken: details.purchase_units[0].payments.captures[0].id,
      amount: details.purchase_units[0].amount.value,
      currency: details.purchase_units[0].amount.currency_code,
      description: details.purchase_units[0].description,
      phoneNumber: details.payer.phone.phone_number.national_number,
      email: details.payer.email_address,
      firstname: details.payer.name.given_name,
      lastname: details.payer.name.surname,

      // Custom fields passed from the order creation
      Username: customData.username,
      //IsCollectionId: customData.IsCollectionId,
      //IsMonthly: customData.IsMonthly,
      //SelectedId: customData.SelectedId,

      // Pass the cartdata directly since it's available globally
      CartItems: filteredCart,  // Use cartdata from the component props
      status: status === "COMPLETED" ? true : false
    };






    // Extract payment status from PayPal's response
    const paymentStatus = details.status;

    // Define messages based on different statuses
    let title = "";
    let icon = "";
    let message = "";
    //let status=false;

    switch (paymentStatus) {
        case "COMPLETED":
            title = "Payment Completed!";
            icon = "success";
            message = "Your payment was successfully completed.";
            break;
        case "PENDING":
            title = "Payment Pending!";
            icon = "warning";
            message = "Your payment is currently pending. Please check your email for further updates.";
            break;
        case "FAILED":
            title = "Payment Failed!";
            icon = "error";
            message = "Unfortunately, your payment has failed. Please try again.";
            break;
        case "DECLINED":
            title = "Payment Declined!";
            icon = "error";
            message = "Your payment was declined. Please check your payment method and try again.";
            break;
        case "CANCELLED":
            title = "Payment Cancelled!";
            icon = "info";
            message = "You cancelled the payment. Feel free to try again if this was a mistake.";
            break;
        case "REFUNDED":
            title = "Payment Refunded!";
            icon = "info";
            message = "Your payment has been refunded.";
            break;
        default:
            title = "Payment Status Unknown";
            icon = "info";
            message = "Something went wrong. Please contact support for assistance.";
    }

    // Display the SweetAlert based on status
    // Swal.fire({
    //     title: title,
    //     text: message,
    //     icon: icon,
    //     confirmButtonText: "OK",
    // });

   //console.log("paymentInfo", JSON.stringify(paymentInfo));
   // alert("");

    const response = await fetch(
      "https://s3napi.s3nsoftware.com/api/UserMasters/SaveSubscriptionInformation",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentInfo),
      }
    );
    ////console.log(response.ok)
    ////console.log(response.status)
   ////console.log(JSON.stringify(response));


    if (response.ok && response.status === 200 ) {
      localStorage.setItem('cart', JSON.stringify([]));
      updateCart([]);
       

      Swal.fire({
        title: title,
        icon: icon,
        text: message,
        confirmButtonText: "Ok",
        allowOutsideClick: false,  // Disable clicks outside the modal
        allowEscapeKey: false,     // Disable Esc key
        allowEnterKey: false       // Disable Enter key
      }).then(() => {
        handleClose();
        window.location.reload();
      });
    } else if (!response.ok) {
      Swal.fire({
        title: "Subscription failed!",
        icon: "error",
        confirmButtonText: "Ok",
      });
      handleClose();
      window.location.reload();
    }
  };

  // Save payment error details to the server
  const savePaymentError = async (error, data) => {
    const errorInfo = {
      errorMessage: error.message,
      errorStack: error.stack || null,
      data: data || {},  // You can include additional data if available
      cartdata: cartdata  // Save the cart data in case of failure too
    };

    //console.log("errorInfo", errorInfo);

    const response = await fetch(
      "https://s3napi.s3nsoftware.com/api/UserMasters/SavePaymentError",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(errorInfo),
      }
    );

    if (response.ok) {
      //console.log("Error information saved successfully");
    } else {
      //console.error("Failed to save error information");
    }
  };

  return (
    <>
      {/* PayPal Modal */}
      <Modal show={showPaypalModel} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Complete Your Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Render PayPal button here */}
          <div ref={paypalRef}></div>
          {paymentCompleted && (
        <div style={{ marginTop: "20px", color: "green" }}>
          Payment completed successfully! Thank you for your purchase.
        </div>
      )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Show success message after payment */}
      
    </>
  );
};

export default PayPalModal;
