import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaUpload, FaTrash, FaCheck, FaTimes, FaMicrophone, FaStop, FaSpinner, FaFileImport, FaStopCircle, FaDownload, FaLine, FaSave } from 'react-icons/fa';
import axios from 'axios';
import styles1 from './Recorder.module.css'; // Import CSS file for styling
import styles from './WordModel.module.css';

const Recorder = (props) => {
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunksList, setAudioChunksList] = useState([]);
    const [recording, setRecording] = useState(false);
    const [progress, setProgress] = useState(0);
    const audioRefs = useRef([]);
    const [uploadStatus, setUploadStatus] = useState([]);
    const [checkedIndex, setCheckedIndex] = useState(0); // Default to first recording
    const fileInputRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false); // State for recording indicator

    useEffect(() => {
        if (audioChunksList.length > 0) {
            setCheckedIndex(0);
        }
        audioRefs.current = audioChunksList.map((_, i) => audioRefs.current[i] || React.createRef());

        ////console.log("Updated audio chunks list:", audioChunksList);

    }, [audioChunksList]);



    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const recorder = new MediaRecorder(stream);
                recorder.ondataavailable = e => {
                    const newAudioChunks = [e.data];
                    const newAudioChunksList = [...audioChunksList, newAudioChunks];
                    const newUploadStatus = [...uploadStatus, 'pending'];
                    setAudioChunksList(newAudioChunksList);
                    setUploadStatus(newUploadStatus);
                    //playNewAudio(newAudioChunks, newAudioChunksList.length - 1); // Play the latest recording

                    setTimeout(() => {
                        playNewAudio(newAudioChunks, newAudioChunksList.length - 1);
                    }, 100);


                };
                recorder.start();
                setMediaRecorder(recorder);
                setRecording(true);
                setProgress(0);
                updateProgress();
                setIsRecording(true);
            })
            .catch(err => {
                //console.error('Error accessing microphone:', err);
            });
    };

    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.state !== 'inactive') {
            mediaRecorder.stop();
            setRecording(false);
            setIsRecording(false);


        }
    };

    const updateProgress = () => {
        if (recording) {
            setProgress(prevProgress => (prevProgress < 100 ? prevProgress + 1 : 100));
            setTimeout(updateProgress, 100);
        } else {
            setProgress(0);
        }
    };

    const playNewAudio = (newAudioChunks, index) => {
        if (audioRefs.current[index]) {
            const audioBlob = new Blob(newAudioChunks, { type: 'audio/mp3' }); // Adjust MIME type as necessary
            const audioUrl = URL.createObjectURL(audioBlob);
            const audioElement = audioRefs.current[index];
            audioElement.src = audioUrl;
            audioElement.load(); // Load the new source into the element

            audioElement.play().then(() => {
                ////console.log("Playback succeeded!");
                // Optionally, highlight the audio controls
                // audioElement.style.outline = "2px solid #4CAF50"; // Example: Green outline
            }).catch(e => {
                //console.error('Error playing the audio:', e);
                alert('Autoplay failed. Please interact with the document first or check browser settings.');
                audioElement.style.outline = "2px solid #f44336"; // Example: Red outline on error
            });
        } else {
            //console.error('Audio element not found at index:', index);
            alert('Audio element not available for playback.');
        }
    };

    const playAudio = index => {
        if (audioRefs.current[index]) {
            const audioBlob = new Blob(audioChunksList[index], { type: 'audio/mp3' }); // Adjust the MIME type based on your recording format
            const audioUrl = URL.createObjectURL(audioBlob);
            audioRefs.current[index].src = audioUrl;
            audioRefs.current[index].play().catch(e => {
                //console.error('Error playing the audio:', e);
                alert('Failed to play the audio. Error: ' + e.message);
            });
        }
    };

    const handleFileImport = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = () => {
                const audioBlob = new Blob([reader.result], { type: file.type });

                const newAudioChunks = [audioBlob];
                const newAudioChunksList = [...audioChunksList, newAudioChunks];
                const newUploadStatus = [...uploadStatus, 'pending'];


                setAudioChunksList(newAudioChunksList);
                setUploadStatus(newUploadStatus);

                setTimeout(() => {
                    playNewAudio(newAudioChunks, newAudioChunksList.length - 1);
                }, 100);

            };
            reader.onerror = (error) => {
                //console.error('Error reading file:', error);
            };
        }
    };

    const handleClickUpload = async (index) => {
        const uname = localStorage.getItem('username');
        const token = localStorage.getItem('webtoken');

        if (!audioChunksList[index]) {
            alert("Please select a file first!");
            return;
        }

        const file = new Blob(audioChunksList[index], { type: 'audio/mp3' });

        const formData = new FormData();
        formData.append('audio', file);
        formData.append('word', props.word);
        formData.append('ischecked', index === checkedIndex);
        formData.append('wordid', props.wid);
        formData.append('username', uname);
        const URL = 'https://s3napi.s3nsoftware.com/api/UserProfiles/UploadAaudioFile';

        try {
            const response = await axios({
                method: 'post',
                url: URL,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                ////console.log('Server responded with:', response.data);
                setUploadStatus(prevStatus => [...prevStatus.slice(0, index), 'success', ...prevStatus.slice(index + 1)]);
            } else {
                ////console.log('Server response status:', response.status);
                alert('Upload failed with status: ' + response.status);
                setUploadStatus(prevStatus => [...prevStatus.slice(0, index), 'failed', ...prevStatus.slice(index + 1)]);
            }
        } catch (error) {
            if (error.response) {
                //console.error('Upload error:', error.response.data);
                alert('Upload failed: ' + error.response.data);
                setUploadStatus(prevStatus => [...prevStatus.slice(0, index), 'failed', ...prevStatus.slice(index + 1)]);
            } else if (error.request) {
                //console.error('Upload error: No response received');
                alert('No response from server');
                setUploadStatus(prevStatus => [...prevStatus.slice(0, index), 'failed', ...prevStatus.slice(index + 1)]);
            } else {
                //console.error('Error', error.message);
                alert('Error: ' + error.message);
                setUploadStatus(prevStatus => [...prevStatus.slice(0, index), 'failed', ...prevStatus.slice(index + 1)]);
            }
        }
    };

    const deleteAudio = index => {
        const updatedAudioChunksList = [...audioChunksList];
        updatedAudioChunksList.splice(index, 1);
        const updatedUploadStatus = [...uploadStatus];
        updatedUploadStatus.splice(index, 1);
        setAudioChunksList(updatedAudioChunksList);
        setUploadStatus(updatedUploadStatus);
        if (checkedIndex === index) {
            setCheckedIndex(null);
        } else if (index < checkedIndex) {
            setCheckedIndex(checkedIndex - 1);
        }
    };

    const handleCheckboxChange = (index) => {
        setCheckedIndex(index === checkedIndex ? null : index);
    };
    const closeModal = () => {
        setAudioChunksList([]);
        props.updateIsOpen(false);

        setUploadStatus([])

    };

    return (
        <div className={styles.modal_container}>
            {props.open && (
                <div className={styles.modal}>
                    <div className={styles.modal_content}>
                        <span className={styles.close} onClick={closeModal}>&times;</span>
                        <div className={styles.button_group} >
                            {recording ? (
                                <FaStopCircle
                                    onClick={stopRecording}
                                    disabled={!recording}
                                    className={styles1.recordingIndicator}
                                    title="Stop Recording"
                                />
                            ) : (
                                <FaMicrophone
                                    onClick={startRecording}
                                    disabled={recording}
                                    style={{ cursor: recording ? 'not-allowed' : 'pointer', fontSize: '50px', margin: '20px' }}
                                    title="Start Recording"
                                />
                            )}
                            <span style={{ fontSize: '20px', fontFamily: 'bold' }}>OR</span>
                            <FaUpload
                                onClick={() => fileInputRef.current.click()}
                                disabled={recording}
                                style={{ cursor: recording ? 'not-allowed' : 'pointer', fontSize: '50px', margin: '20px' }}
                                title="Import Audio"
                            />
                            <input
                                ref={fileInputRef}
                                type="file"
                                style={{ display: 'none' }}
                                accept="audio/*"
                                onChange={handleFileImport}
                            />
                        </div>
                        <div>
                            {recording && <div className={styles.progress_bar} style={{ width: `${progress}%` }}></div>}
                        </div>
                        <div className={styles1.audio_list}>
                            <span>1. For recording click on mic icon</span>
                            <span>2. To stop the recording click on stop icon </span>
                            <span>3. Import file from device click on import icon</span>
                            <span>4. Checkbox is for which one is main audio</span>
                            {audioChunksList.map((chunks, index) => (
                                <div key={index} className={styles1.audio_item}>
                                    <audio ref={ref => audioRefs.current[index] = ref} controls />
                                    <div className={styles1.icon_container}>
                                        <FaPlay title='Play the audio' className={styles1.icon} onClick={() => playAudio(index)} />
                                        <FaSave title='Save the audio' className={styles1.icon} onClick={() => handleClickUpload(index)} />
                                        <FaTrash title='Delete the ausio' className={styles1.icon} onClick={() => deleteAudio(index)} />
                                        <input
                                            type="checkbox" 
                                            title='check for the main audio'
                                            checked={index === checkedIndex}
                                            onChange={() => handleCheckboxChange(index)}
                                        />
                                        {uploadStatus[index] === 'success' ? <FaCheck className={styles1.icon} color="green" /> : uploadStatus[index] === 'failed' ? <FaTimes className={styles1.icon} color="red" /> : null}
                                        {uploadStatus[index] === 'success' ? <>Uploaded</> : uploadStatus[index] === 'failed' ? <>Failed</> : null}

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Recorder;
