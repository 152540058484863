import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Play from "../../assets/img/play.png";
import Pause from "../../assets/img/pause.png";
import { FaEye, FaEyeSlash, FaVolumeUp } from "react-icons/fa";
import TextToSpeech from "./TextToSpeech";

const PowerMode = ({
  qns,
  dictionary,
  updateSetAnswer,
  corrects,
  wrongs,
  updateCorrect,
  updateWrongs,
  updateRefresh,
  refresh,
  updateDictionary,
  updatWordStatistics,
}) => {
  const [inputValues, setInputValues] = useState({});
  const [initialDisabledFields, setInitialDisabledFields] = useState({});

  const enabledQuestions = []; // Array to store enabled input questions

  useEffect(() => {
    // Parse dictionary and pre-fill inputs based on word IDs
    const parseDictionary = () => {
     // console.warn(`Skipping invalid entry: ${typeof dictionary}`);

      if (dictionary === null) {
       // console.warn("Dictionary is empty, null, undefined, invalid, or not a string. Skipping parsing.");
        return;
      }
      const parsedValues = {};
      const disabledFields = {}; // Track fields that should be initially disabled

      try {
        Object.entries(dictionary).forEach(([key, value]) => {
         // console.log(`Key: ${key}, Value: ${value}`);
          if (!key || !value) {
         //  console.warn(`Skipping invalid entry: ${key}`);
            return;
          }

          const parts = key.trim().split("|");
          if (parts.length >= 2) {
            const wordId = parts[1].trim(); // Extract the wordId
            const inputAnswer = value.trim().replace(/['"]/g, ""); // Remove surrounding quotes
            if (wordId && inputAnswer) {
              parsedValues[wordId] = inputAnswer; // Map wordId to the input answer
              disabledFields[wordId] = true; // Initially disable these fields
            }
          }
        });

        // Update inputValues and initialDisabledFields states with parsed data
        setInputValues(parsedValues);
        setInitialDisabledFields(disabledFields);
      } catch (error) {
        //console.error("Error parsing dictionary:", error);
      }
    };

    parseDictionary();
  }, [dictionary]);

  const getSpecialCharacters = (inputString) => {
    const specialCharacterRegex = /[^a-zA-Z0-9\s]/g;
    const specialCharactersArray = inputString.match(specialCharacterRegex);
    return specialCharactersArray ? specialCharactersArray.join("") : "";
  };

  const checkInputValidations = (spelling, actualWord) => {
    const specialCharacters = getSpecialCharacters(actualWord);
    const lastChar = spelling.slice(-1);
    if (!specialCharacters.includes(lastChar)) {
      return spelling.slice(0, -1) + lastChar.replace(/[^A-Za-z]/g, "");
    }
    return spelling;
  };

  const handleChange = (event, id, actualWord) => {
    const newValue = event.target.value;
    const validatedValue = checkInputValidations(newValue, actualWord);
    setInputValues((prev) => ({ ...prev, [id]: validatedValue }));

    // Enable the input field after the user provides an answer
    if (newValue !== "") {
      setInitialDisabledFields((prev) => ({ ...prev, [id]: false }));
    }
  };

  // Function to compare answers and get ASCII values
  const compareAnswers = (userAnswer, correctAnswer) => {
    const correct = userAnswer.toLowerCase() === correctAnswer.toLowerCase();
    const userASCII = [...userAnswer]
      .map((char) => char.charCodeAt(0))
      .join(", ");
    const correctASCII = [...correctAnswer]
      .map((char) => char.charCodeAt(0))
      .join(", ");
    return { correct, userASCII, correctASCII };
  };

  // Handle Submit
  const handleSubmit = (async) => {
    const updatedDictionary = {};
    let answerStatusString = "";

    let corrects = 0;
    let wrongs = 0;

    const newlyEnteredValues = []; // Array to store newly entered values

    qns.forEach((question) => {
      const wordId = question.Id;
      const correctAnswer = dictionary[wordId] || "";
      const userInput = inputValues[wordId] || "";

      const newlySubmittedQuestions = {};

      // Compare user input with correct answer
      // const isCorrect = userInput.toLowerCase() === question.utfascii.toLowerCase() ? 1 : 2;

      const isCorrect =
        userInput === ""
          ? 0
          : userInput.toLowerCase() === question.utfascii.toLowerCase()
          ? 1
          : 2;
      answerStatusString += `${isCorrect}`;

      if (isCorrect === 1) {
        corrects++;
      } else if (isCorrect === 2) {
        wrongs++;
      }

      // Format key as "correctstatus|wordid|correctanswer"
      const formattedKey = `${isCorrect}|${wordId}|${question.utfascii}`;

      if (isCorrect === 1 || isCorrect === 2) {
        // Store user input as value
        updatedDictionary[formattedKey] = userInput;
      }

      // Store submitted questions to disable them
      if (isCorrect !== 0) {
        newlySubmittedQuestions[wordId] = true;
      }

      // Collect only newly entered values (input must not be empty and must not be initially disabled)
      if (
        !initialDisabledFields[wordId] &&
        userInput.trim() !== "" &&
        !dictionary[wordId] &&
        wordId === question.Id
      ) {
        newlyEnteredValues.push({
          Id: question.Id,
          Word: question.Word,
          UserInput: userInput,
        });

        updatWordStatistics(
          wordId,
          question.Word,
          userInput,
          isCorrect,
          question.QuizType
        );
        //console.log("userinput:", userInput); // Print newly entered values
      }
    });
    updateWrongs(wrongs);
    updateCorrect(corrects);

   // console.log("Updated Dictionary:", updatedDictionary);

    //console.log("Updated Answers:", answerStatusString);

    if (corrects + wrongs === qns.length) {
      updateSetAnswer(answerStatusString, updatedDictionary, true);
    } else {
      updateSetAnswer(answerStatusString, updatedDictionary, false);
      updateRefresh(true);
    }

    // You can now send 'updatedDictionary' to an API or process it further
  };

  // const params = {
  //   WordId: cq.Id, // Replace with your WordId
  //   Word: cq.Word, // Replace with your Word
  //   QuizId: props.quizId, // Replace with your QuizId
  //   Username: username, // Replace with your Username
  //   Answer: cq.Word, // Replace with your Answer
  //   InputAnswer: input, // Replace with your InputAnswer
  //   QuizStatus: isCorrect, // Replace with your QuizStatus
  //   QuizType: cq.QuizType, // Replace with your QuizType
  // };
  // updateStatistics(params);
  //

  const playerBgStyle = {
    background: `
      linear-gradient(145deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1)),
      linear-gradient(to bottom, #4a79c9 0%, #0b3476 100%)
    `,
    WebkitBackdropFilter: "blur(10px)", // Support for Webkit browsers
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    border: "1px solid rgba(255, 255, 255, 0.3)", // Subtle border for the glass effect
    borderRadius: "15px", // Rounded corners for better appearance
    padding: "30px",
    marginTop: "20px",
    marginBottom: "5px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Adds depth to the container
  };

  const controlsStyle = {
    width: "100%",
    margin: "auto",
  };

  const getBackgroundColor = (questionId) => {
    // Find the dictionary key that contains this question ID
    const dictEntryKey = Object.keys(dictionary).find((key) =>
      key.includes(`|${questionId}|`)
    );

    if (!dictEntryKey) return "white"; // If no entry, return default (unanswered)

    const [status] = dictEntryKey.split("|"); // Extract the first number (status)

    switch (status) {
      case "2":
        return "red"; // Correct
      case "1":
        return "green"; // Incorrect
      case "0":
        return "white"; // Empty
      default:
        return "white"; // Default case
    }
  };

  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const boxStyle = {
    padding: "12px",
    backgroundColor: "#ffffff",
    borderRadius: "6px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const audioRefs = useRef({}); // Store PowerModePlayer refs

  const handleFocus = (questionId) => {
    if (
      audioRefs.current[questionId] &&
      audioRefs.current[questionId].playAudio
    ) {
      audioRefs.current[questionId].playAudio();
    }
  };

  const inputRefs = useRef({}); // Store references to all input fields

  const handleKeyDown = (event, currentId) => {
    if (event.key === "Enter") {
      event.preventDefault();

      // Get all question IDs
      const questionIds = qns.map((q) => q.Id);
      const currentIndex = questionIds.indexOf(currentId);

      // Find the next available input
      for (let i = currentIndex + 1; i < questionIds.length; i++) {
        const nextId = questionIds[i];
        if (inputRefs.current[nextId] && !initialDisabledFields[nextId]) {
          inputRefs.current[nextId].focus(); // Move focus
          handleFocus(nextId); // Play next question's audio

          return;
        }
      }
    }
  };

  return (
    <div className="shadow" style={playerBgStyle}>
      <div className="row" style={controlsStyle}>
        <div className="shadow p-4" style={{ marginTop: "20px" }}>
          <h1>All Questions</h1>
          <div style={{ overflowX: "auto" }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid #ddd",
              }}
            >
              <thead>
                <tr>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                    Play
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                    Answer
                  </th>
                  <th
                    className="hide-on-mobile"
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      width: "10%",
                    }}
                  >
                    POS
                  </th>
                  <th
                    className="hide-on-mobile"
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      width: "20%",
                    }}
                  >
                    Definition
                  </th>
                  <th
                    className="hide-on-mobile"
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      width: "10%",
                    }}
                  >
                    Etymology
                  </th>
                  <th
                    className="hide-on-mobile"
                    style={{
                      padding: "10px",
                      border: "1px solid #ddd",
                      width: "20%",
                    }}
                  >
                    Sentence
                  </th>
                  <th
                    className="show-on-mobile"
                    style={{ padding: "10px", border: "1px solid #ddd" }}
                  >
                    Info
                  </th>
                </tr>
              </thead>
              <tbody>
                {qns.map((question, index) => (
                  <React.Fragment key={index}>
                    {/* Main Row */}

                    <tr>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        {question.QuizType === 1 && (
                          <PowerModePlayer
                            audioUrl={question.audio}
                            ref={(el) => (audioRefs.current[question.Id] = el)}
                          />
                        )}
                      </td>
                      <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                        <input
                          type="text"
                          spellCheck="false"
                          onContextMenu={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                          onCopy={(e) => e.preventDefault()}
                          onCut={(e) => e.preventDefault()}
                          onDrag={(e) => e.preventDefault()}
                          onDrop={(e) => e.preventDefault()}
                          autoComplete="off"
                          autoCapitalize="off"
                          autoCorrect="off"
                          ref={(el) => (inputRefs.current[question.Id] = el)} // Store ref
                          onFocus={() => handleFocus(question.Id)}
                          onKeyDown={(e) => handleKeyDown(e, question.Id)}
                          style={{
                            width: "100%",
                            padding: "5px",
                            backgroundColor: getBackgroundColor(
                              question.Id,
                              question.utfascii
                            ),
                            color:
                              getBackgroundColor(
                                question.Id,
                                question.utfascii
                              ) === "white"
                                ? "black"
                                : "white",
                            border: "1px solid black",
                          }}
                          value={inputValues[question.Id] || ""}
                          onChange={(e) =>
                            handleChange(
                              e,
                              question.Id,
                              question.utfascii || ""
                            )
                          }
                          placeholder="Enter Spelling"
                          disabled={initialDisabledFields[question.Id] || false}
                        />
                      </td>
                      <td
                        className="hide-on-mobile"
                        style={{ padding: "10px", border: "1px solid #ddd" }}
                      >
                        {question.pos || "N/A"}{" "}
                        <TextToSpeech text={question.pos} />
                      </td>
                      <td
                        className="hide-on-mobile"
                        style={{ padding: "10px", border: "1px solid #ddd" }}
                      >
                        {question.Quizdefn || "N/A"}{" "}
                        <TextToSpeech text={question.Quizdefn} />
                      </td>
                      <td
                        className="hide-on-mobile"
                        style={{ padding: "10px", border: "1px solid #ddd" }}
                      >
                        {question.Quizetym || "N/A"}{" "}
                        <TextToSpeech text={question.Quizetym} />
                      </td>
                      <td
                        className="hide-on-mobile"
                        style={{ padding: "10px", border: "1px solid #ddd" }}
                      >
                        {question.Quizsent || "N/A"}{" "}
                        <TextToSpeech text={question.Quizsent} />
                      </td>
                      <td
                        className="show-on-mobile"
                        style={{ padding: "10px", border: "1px solid #ddd" }}
                      >
                        <button
                          onClick={() => toggleRow(index)}
                          style={{
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                            fontSize: "18px",
                          }}
                        >
                          {expandedRow === index ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      </td>
                    </tr>

                    {expandedRow === index && (
                      <tr
                        className="show-hide-mobile"
                        style={{
                          padding: "15px",
                          border: "5px solid #ddd",
                          textAlign: "left",
                          boxShadow: "0px 8px 20px rgba(68, 45, 45, 0.1)",
                          borderRadius: "10px", // Slightly larger rounded corners
                          backgroundColor: "#fff", // Keeps content distinct from table background
                        }}
                      >
                        <td colSpan="100%">
                          <strong style={{ color: "black" }}>
                            Definition: &nbsp;
                          </strong>

                          <span style={{ color: "black" }}>
                            {question.Quizdefn || "N/A"}{" "}
                            <TextToSpeech text={question.defn} />
                          </span>
                          <br />
                          <strong style={{ color: "black" }}>
                            Etymology: &nbsp;
                          </strong>
                          <span style={{ color: "black" }}>
                            {question.Quizetym || "N/A"}{" "}
                            <TextToSpeech text={question.etym} />
                          </span>
                          <br />
                          <strong style={{ color: "black" }}>
                            Sentence: &nbsp;
                          </strong>
                          <span style={{ color: "black" }}>
                            {question.Quizsent || "N/A"}{" "}
                            <TextToSpeech text={question.sent} />
                          </span>
                          <br />
                          <strong style={{ color: "black" }}>
                            POS: &nbsp;
                          </strong>
                          <span style={{ color: "black" }}>
                            {question.pos || "N/A"}{" "}
                            <TextToSpeech text={question.pos} />
                          </span>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <button
            onClick={handleSubmit}
            style={{
              padding: "10px 20px",
              marginTop: "20px",
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            Save / Submit
          </button>
        </div>
      </div>
    </div>
  );
};
const PowerModePlayer = forwardRef(({ audioUrl }, ref) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1.0); // Default playback speed

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate; // Apply playback rate before playing
      audioRef.current.play().catch((e) => {
        console.error("Error playing audio:");
      });
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  useImperativeHandle(ref, () => ({
    playAudio: () => {
      if (audioRef.current) {
        audioRef.current.playbackRate = playbackRate;
        audioRef.current
          .play()
          .catch((e) => console.error("Error playing audio:"));
      }
    },
  }));

  useEffect(() => {
    const _ttsPlaybackRate = localStorage.getItem("ttsPlaybackRate") || "1.0"; // Fetch playback rate
    setPlaybackRate(parseFloat(_ttsPlaybackRate)); // Update playback rate state
  }, []);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      audioElement.playbackRate = playbackRate; // Apply playback rate dynamically
    }
  }, [playbackRate]); // Update when playback rate changes

  useEffect(() => {
    const audioElement = audioRef.current;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);

    if (audioElement) {
      audioElement.addEventListener("play", handlePlay);
      audioElement.addEventListener("pause", handlePause);
      audioElement.addEventListener("ended", handleEnded);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("play", handlePlay);
        audioElement.removeEventListener("pause", handlePause);
        audioElement.removeEventListener("ended", handleEnded);
      }
    };
  }, []);

  const formatAudioUrl = (url) => {
    if (!url) return "";
    const mp3 = url.replace(/^sound:\/\//, ""); // Replace "sound://" with an empty string
    return `https://s3nsoftware.com/${mp3}`; // Construct the full URL
  };

  const iconStyle = {
    cursor: "pointer",
    height: "50px",
    width: "50px",
    opacity: 0.9,
  };

  return (
    <>
      <audio ref={audioRef} src={formatAudioUrl(audioUrl)} preload="auto" />
      <div id="play-button" style={{ display: "inline-block" }}>
        {isPlaying ? (
          <img
            style={iconStyle}
            src={Pause}
            onClick={togglePlayPause}
            alt="Pause"
          />
        ) : (
          <img
            style={iconStyle}
            src={Play}
            onClick={togglePlayPause}
            alt="Play"
          />
        )}
      </div>
    </>
  );
});
export default PowerMode;
